.steps {

	.progress_item {

		.icon {
			width: 56px;
			height: 56px;
			border: 3px solid #DEDEDE;
			border-radius: 30px;
			text-align: center;
		}
	}
}

.dashboard_item {

	.text-muted {
		color: #999999!important;
	}

	.numbered {
		font-size: 20px;
		color: white;
		font-weight: bold;
		background: #131936;
		width: 40px;
		height: 40px;
		display: block;
		border-radius: 20px;
		text-align: center;
		padding-top: 5px;

		@media (max-width:667px) {
			display: inline-block;
			margin: auto;
		}
	}

	.numbered_muted {
		color: #999999;
		border:	2px solid #999999;
		width: 40px;
		height: 40px;
		display: block;
		border-radius: 20px;
		text-align: center;
		padding-top: 5px;
		font-weight: bold;
		@media (max-width:667px) {
			display: inline-block;
			margin: auto;
		}
	}
}

.dashboard_card {
	background: #F8F8F9;
	border-radius: 8px;
	padding: 24px 32px;
	@media (max-width:667px) {
		border-radius: 4px;
	}

	h6 {
		@media (max-width:667px) {
			font-size: 16px;
		}
	}

	.btn_submit {
		@media (max-width:667px) {
			text-align: center;
		}
	}

	.time {
		background: #E1E4EC;
		border-radius: 4px;
		padding: 4px 8px;
		color: #333;
		font-size: 14px;
		margin-top: -4px;
	}
}
