.calender_today {
	font-size: 14px;
	font-weight: 700;
	color: #004C7F;
	background: none;
    border: none;
}

.tui-full-calendar-dayname-date,
.tui-full-calendar-dayname-name {
	font-size: 16px!important;
	font-weight: 400!important;
}
