.tooltip-inner {
	background-color: #131936;
	box-shadow: none;
	opacity: 1;
	z-index: 99999;
	line-height: 20px;
	color: white!important;
	font-size: 14px!important;
	text-align: left;
	// width: 424px !important;
	// min-width: 424px;
	padding: 15px;
}

.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #131936 !important;
}
.tooltip_icon {
	margin-top: -4px;
}

.availability_calendar {

	.tooltip-inner {
		background-color: #131936;
		box-shadow: none;
		opacity: 1;
		z-index: 99999;
		line-height: 20px;
		color: white !important;
		font-size: 14px !important;
		text-align: left;
		padding: 15px;
	}
	
	.tooltip.bs-tooltip-bottom .arrow:before {
		border-bottom-color: #131936 !important;
	}
	.tooltip_icon {
		margin-top: -4px;
	}


}