.gantt .grid-background {
	fill: none;
}

.gantt .grid-header {
	fill: #ffffff;
	stroke: #e0e0e0;
	stroke-width: 1.4;
}

.gantt .grid-row {
	fill: #ffffff;
}

.gantt .grid-row:nth-child(even) {
	fill: #f5f5f5;
}

.gantt .row-line {
	stroke: #ebeff2;
}

.gantt .tick {
	stroke: #e0e0e0;
	stroke-width: 0.2;
}
.gantt .tick.thick {
	stroke-width: 0.4;
}

.gantt .today-highlight {
	fill: transparent;
	opacity: 1;
}

.gantt .arrow {
	fill: none;
	stroke: #131936;
	stroke-width: 1.4;
}

.gantt .bar {
	fill: #004C7F;
	stroke: #004C7F;
	stroke-width: 0;
	transition: stroke-width .3s ease;
	user-select: none;

	&:hover {
		fill: #131936;
	}
}

.gantt .bar-progress {
	fill: #3279C6;
	opacity: 0.4;
}

.gantt .bar-invalid {
	fill: transparent;
	stroke: #8D99A6;
	stroke-width: 1;
	stroke-dasharray: 5;
}
.gantt .bar-invalid ~ .bar-label {
	fill: #555;
}

.gantt .bar-label {
	fill: #fff;
	dominant-baseline: central;
	text-anchor: start;
	font-size: 12px;
	font-weight: bold;
}

.gantt tspan {
	font-weight: normal;
}

.gantt .bar-label.big {
	fill: #555;
	text-anchor: start;
}

.gantt .handle {
	fill: #ddd;
	cursor: ew-resize;
	opacity: 0;
	visibility: hidden;
	transition: opacity .3s ease;
}

.gantt .bar-wrapper {
	cursor: pointer;
	outline: none;
}
.gantt .bar-wrapper:hover .bar {
	fill: #004C7F;
}
.gantt .bar-wrapper:hover .bar-progress {
	fill: #3E91EA;
}
.gantt .bar-wrapper:hover .handle {
	visibility: visible;
	opacity: 1;
}
.gantt .bar-wrapper.active .bar {
	fill: #004C7F;
}
.gantt .bar-wrapper.active .bar-progress {
	fill: #3E91EA;
}

.gantt .lower-text, .gantt .upper-text {
	font-size: 12px;
	text-anchor: middle;
}

.gantt .upper-text {
	fill: #555;
}

.gantt .lower-text {
	fill: #333;
}

.gantt .hide {
	display: none;
}

.gantt-container {
	position: relative;
	overflow: auto;
	font-size: 12px;
}
.gantt-container .popup-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);
	padding: 0;
	color: #fff;
	border-radius: 3px;
}
.gantt-container .popup-wrapper .title {
	border-bottom: 3px solid #004C7F;
	padding: 10px;
}
.gantt-container .popup-wrapper .subtitle {
	padding: 10px;
	color: #dfe2e5;
}
.gantt-container .popup-wrapper .pointer {
	position: absolute;
	height: 5px;
	margin: 0 0 0 -5px;
	border: 5px solid transparent;
	border-top-color: rgba(0, 0, 0, 0.8);
}

.handle-group {
	display: none!important;
}

// Custom colors

.training_bg g rect {
	fill: #F2F2F2 !important;
	stroke: #DEDEDE !important;
	color: #131936 !important;
	stroke-width: 1.4 !important;
}

.training_bg g text {
	color: #131936 !important;
	fill: #131936 !important;
}

.unassigned_bg g rect {
	fill: #fff !important;
	stroke: #DEDEDE !important;
	color: #131936 !important;
	stroke-width: 1.4 !important;
}

.unassigned_bg g text {
	color: #131936 !important;
	fill: #131936 !important;
}

.completed_bg g rect {
	fill: #F2F2F2 !important;
	stroke: #DEDEDE!important;
	color: #131936 !important;
	stroke-width: 1.4 !important;
}

.completed_bg g text {
	color: #131936 !important;
	fill: #131936 !important;
}

.weekend-highlight {
	fill: #dcdcdc;
	opacity: 0.5;
}

.gantt .grid-row:nth-child(even) {
    fill: #fff;
}

// .audit_bg .bar-label.big {
//     fill: #fff !important;
// }

.audit_bg .big tspan.righthours {
   fill: #fff !important;
}