/*!
 * AnyChart is lightweight robust charting library with great API and Docs, that works with your stack and has tons of chart types and features.
 * Version: 2021-05-19
 * License: https://www.anychart.com/buy/
 * Contact: sales@anychart.com
 * Copyright: AnyChart.com 2021. All rights reserved.
 */
 .anychart-ui-support {
    border-style: hidden;
   }
   * [class^="anychart"] {
    outline: none;
   }
   .anychart-inline-block {
    position: relative;
    display: -moz-inline-box;
    display: inline-block;
   }
   * html .anychart-inline-block {
    display: inline;
   }
   * :first-child + html .anychart-inline-block {
    display: inline;
   }
   .anychart-hidden {
    display: none;
   }
   .anychart-control-disabled {
    color: #cccccc;
   }
   .anychart-label-input {
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    border: 1px solid #d9d9d9;
    border-top: 1px solid silver;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    font-size: 13px;
    height: 16px;
    padding: 5px 4px;
   }
   .anychart-label-input:focus {
    border-color: #4d90fe;
   }
   .anychart-label-input.anychart-label-input-label-disabled {
    color: #cccccc;
   }
   .anychart-thumbnail {
    display: block;
    padding: 4px;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 2px;
    -webkit-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
   }
   .anychart-thumbnail > img {
    margin-right: auto;
    margin-left: auto;
    display: block;
    max-width: 100%;
    height: auto;
   }
   .anychart-thumbnail:hover,
   .anychart-thumbnail:focus {
    border-color: #cccccc;
    box-shadow: 0 1px 3px rgba(0,0,0,.2);
   }
   .anychart-thumbnail:active {
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,0.3);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.3);
    border-color: #489adc;
   }
   .anychart-loader {
    background-color: rgba(255,255,255,.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
   }
   .anychart-loader .anychart-loader-rotating-cover {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 50%;
    margin-top: -35px;
    left: 50%;
    margin-left: -35px;
   }
   .anychart-loader .anychart-loader-rotating-plane {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 20%;
    border: 5px solid #1c75ba;
    margin: 0 auto;
    position: relative;
    -webkit-animation: anychart-loader-rotate-plane 3s infinite;
    animation: anychart-loader-rotate-plane 3s infinite;
   }
   .anychart-loader .anychart-loader-chart-row {
    position: absolute;
    top: 10px;
    bottom: 0;
    left: 10px;
    right: 10px;
    letter-spacing: -3px;
    line-height: 0;
    font-size: 0;
    white-space: nowrap;
   }
   .anychart-loader .anychart-loader-chart-row .anychart-loader-chart-col {
    display: inline-block;
    width: 25%;
    height: 90%;
    background: #000000;
    margin: 0 12.5% 0 0;
    vertical-align: bottom;
   }
   .anychart-loader .anychart-loader-chart-row .anychart-loader-chart-col.anychart-loader-green {
    background: #26a957;
    height: 50%;
    -webkit-animation: anychart-loader-blink-plane 1.5s infinite;
    animation: anychart-loader-blink-plane 1.5s infinite;
   }
   .anychart-loader .anychart-loader-chart-row .anychart-loader-chart-col.anychart-loader-orange {
    background: #ff8207;
    height: 70%;
    -webkit-animation: anychart-loader-blink-plane 1.5s infinite .15s;
    animation: anychart-loader-blink-plane 1.5s infinite .25s;
   }
   .anychart-loader .anychart-loader-chart-row .anychart-loader-chart-col.anychart-loader-red {
    background: #f0402e;
    height: 90%;
    -webkit-animation: anychart-loader-blink-plane 1.5s infinite .3s;
    animation: anychart-loader-blink-plane 1.5s infinite .5s;
   }
   @keyframes anychart-loader-rotate-plane {
    0% {
     -webkit-transform: perspective(120px)rotateX(0deg)rotateY(0deg);
     transform: perspective(120px)rotateX(0deg)rotateY(0deg);
     opacity: 1;
    }
    25% {
     -webkit-transform: perspective(120px)rotateX(-180.1deg)rotateY(0deg);
     transform: perspective(120px)rotateX(-180.1deg)rotateY(0deg);
     opacity: .3;
    }
    50% {
     -webkit-transform: perspective(120px)rotateX(-180deg)rotateY(-179.9deg);
     transform: perspective(120px)rotateX(-180deg)rotateY(-179.9deg);
     opacity: 1;
    }
    75% {
     -webkit-transform: perspective(120px)rotateX(0deg)rotateY(-180.1deg);
     transform: perspective(120px)rotateX(0deg)rotateY(-180.1deg);
     opacity: .3;
    }
    100% {
     -webkit-transform: perspective(120px)rotateX(0deg)rotateY(0deg);
     transform: perspective(120px)rotateX(0deg)rotateY(0deg);
     opacity: 1;
    }
   }
   @keyframes anychart-loader-blink-plane {
    0% {
     opacity: 1;
    }
    50% {
     opacity: .01;
    }
    100% {
     opacity: 1;
    }
   }
   .anychart-custom-button {
    margin: 2px;
    border: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    color: #000000;
    background: #dddddd url("https://cdn.anychart.com/ACDVF/button-bg.png") repeat-x top left;
    text-decoration: none;
    list-style: none;
    vertical-align: middle;
    cursor: pointer;
    outline: none;
   }
   .anychart-custom-button-outer-box,
   .anychart-custom-button-inner-box {
    border-style: solid;
    border-color: #aaaaaa;
    vertical-align: top;
   }
   .anychart-custom-button-outer-box {
    margin: 0;
    border-width: 1px 0;
    padding: 0;
   }
   .anychart-custom-button-inner-box {
    margin: 0 -1px;
    border-width: 0 1px;
    padding: 3px 4px;
    white-space: nowrap;
   }
   * html .anychart-custom-button-inner-box {
    left: -1px;
   }
   * html .anychart-custom-button-rtl .anychart-custom-button-outer-box {
    left: -1px;
   }
   * html .anychart-custom-button-rtl .anychart-custom-button-inner-box {
    right: auto;
   }
   * :first-child + html .anychart-custom-button-inner-box {
    left: -1px;
   }
   * :first-child + html .anychart-custom-button-rtl .anychart-custom-button-inner-box {
    left: 1px;
   }
   ::root .anychart-custom-button,
   ::root .anychart-custom-button-outer-box {
    line-height: 0;
   }
   ::root .anychart-custom-button-inner-box {
    line-height: normal;
   }
   .anychart-custom-button-disabled {
    background-image: none !important;
    opacity: 0.3;
    -moz-opacity: 0.3;
    filter: alpha(opacity=30);
    cursor: default;
   }
   .anychart-custom-button-disabled .anychart-custom-button-outer-box,
   .anychart-custom-button-disabled .anychart-custom-button-inner-box {
    color: #333333 !important;
    border-color: #999999 !important;
   }
   * html .anychart-custom-button-disabled {
    margin: 2px 1px !important;
    padding: 0 1px !important;
   }
   * :first-child + html .anychart-custom-button-disabled {
    margin: 2px 1px !important;
    padding: 0 1px !important;
   }
   .anychart-custom-button-hover .anychart-custom-button-outer-box,
   .anychart-custom-button-hover .anychart-custom-button-inner-box {
    border-color: #99ccff #6699ee #6699ee #77aaff !important;
   }
   .anychart-custom-button-active,
   .anychart-custom-button-checked {
    background-color: #bbbbbb;
    background-position: bottom left;
   }
   .anychart-custom-button-focused .anychart-custom-button-outer-box,
   .anychart-custom-button-focused .anychart-custom-button-inner-box {
    border-color: orange;
   }
   .anychart-custom-button-collapse-right,
   .anychart-custom-button-collapse-right .anychart-custom-button-outer-box,
   .anychart-custom-button-collapse-right .anychart-custom-button-inner-box {
    margin-right: 0;
   }
   .anychart-custom-button-collapse-left,
   .anychart-custom-button-collapse-left .anychart-custom-button-outer-box,
   .anychart-custom-button-collapse-left .anychart-custom-button-inner-box {
    margin-left: 0;
   }
   .anychart-custom-button-collapse-left .anychart-custom-button-inner-box {
    border-left: 1px solid #ffffff;
   }
   .anychart-custom-button-collapse-left.anychart-custom-button-checked.anychart-custom-button-inner-box {
    border-left: 1px solid #dddddd;
   }
   * html .anychart-custom-button-collapse-left .anychart-custom-button-inner-box {
    left: 0;
   }
   * :first-child + html .anychart-custom-button-collapse-left.anychart-custom-button-inner-box {
    left: 0;
   }
   .anychart-button {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background-color: #f5f5f5;
    background-image: -webkit-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    border: 1px solid #dcdcdc;
    border: 1px solid rgba(0,0,0,0.1);
    color: #333333;
    cursor: pointer;
    text-align: center;
    font-family: inherit;
    font-size: 11px;
    font-weight: bold;
    height: 29px;
    line-height: 27px;
    margin-right: 16px;
    min-width: 52px;
    outline: 0;
    padding: 0 8px;
    white-space: nowrap;
   }
   .anychart-button:focus {
    border-color: #4d90fe;
   }
   .anychart-button:hover {
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.1);
    -moz-box-shadow: 0 1px 1px rgba(0,0,0,.1);
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
   }
   .anychart-button:active {
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,0.3);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.3);
   }
   .anychart-button i {
    font-size: 11px;
   }
   .anychart-button.anychart-button-disabled:active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    cursor: default;
   }
   .anychart-button-primary {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background-color: #4898e6;
    background-image: -webkit-linear-gradient(to bottom,#4898e6,#4089d0);
    background-image: -moz-linear-gradient(to bottom,#4898e6,#4089d0);
    background-image: -ms-linear-gradient(to bottom,#4898e6,#4089d0);
    background-image: -o-linear-gradient(to bottom,#4898e6,#4089d0);
    background-image: linear-gradient(to bottom,#4898e6,#4089d0);
    border: 1px solid #1976d2;
    color: #ffffff;
    cursor: pointer;
    font-size: 11px;
    font-weight: bold;
    height: 26px;
    line-height: 24px;
    margin: 0 16px 0 0;
    min-width: 70px;
    outline: 0;
    padding: 0 7px;
   }
   .anychart-button-primary:hover,
   .anychart-button-primary:active {
    background-color: #4898e6;
    background-image: -webkit-linear-gradient(to bottom,#4898e6,#387ec3);
    background-image: -moz-linear-gradient(to bottom,#4898e6,#387ec3);
    background-image: -ms-linear-gradient(to bottom,#4898e6,#387ec3);
    background-image: -o-linear-gradient(to bottom,#4898e6,#387ec3);
    background-image: linear-gradient(to bottom,#4898e6,#387ec3);
    border: 1px solid #1976d2;
    color: #ffffff;
   }
   .anychart-button-primary:focus {
    -webkit-box-shadow: inset 0 0 0 1px #ffffff;
    -moz-box-shadow: inset 0 0 0 1px #ffffff;
    box-shadow: inset 0 0 0 1px #ffffff;
    outline: 0;
   }
   .anychart-button-primary:active {
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.3);
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.3);
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.3);
   }
   .anychart-button-primary.anychart-button-disabled {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: #4d90fe;
    background-image: -webkit-linear-gradient(to bottom,#4d90fe,#4089d0);
    background-image: -moz-linear-gradient(to bottom,#4d90fe,#4089d0);
    background-image: -ms-linear-gradient(to bottom,#4d90fe,#4089d0);
    background-image: -o-linear-gradient(to bottom,#4d90fe,#4089d0);
    background-image: linear-gradient(to bottom,#4d90fe,#4089d0);
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default;
   }
   .anychart-button-secondary {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background-color: #f5f5f5;
    background-image: -webkit-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    border: 1px solid #dcdcdc;
    color: #333333;
    cursor: pointer;
    font-size: 11px;
    font-weight: bold;
    height: 26px;
    line-height: 24px;
    margin: 0 16px 0 0;
    min-width: 70px;
    outline: 0;
    padding: 0 7px;
   }
   .anychart-button-secondary:hover,
   .anychart-button-secondary:active {
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    background-color: #f8f8f8;
    background-image: -webkit-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    border: 1px solid #c6c6c6;
    color: #111111;
   }
   .anychart-button-secondary:focus {
    border: 1px solid #4d90fe;
   }
   .anychart-button-secondary:active {
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
   }
   .anychart-button-secondary.anychart-button-disabled {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #f5f5f5;
    background-image: -webkit-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    border: 1px solid #f3f3f3;
    border: 1px solid rgba(0,0,0,0.05);
    color: #b8b8b8;
    cursor: default;
   }
   .anychart-button-standard {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #f5f5f5;
    background-image: -webkit-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    color: #333333;
    border: 1px solid #dcdcdc;
    border: 1px solid rgba(0,0,0,0.1);
   }
   .anychart-button-standard:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #f8f8f8;
    background-image: -webkit-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    border: 1px solid #c6c6c6;
    color: #111111;
   }
   .anychart-button-standard:active {
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    background: #f8f8f8;
    color: #111111;
   }
   .anychart-button-standard.anychart-button-checked {
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    background-color: #eeeeee;
    background-image: -webkit-linear-gradient(to bottom,#eeeeee,#e0e0e0);
    background-image: -moz-linear-gradient(to bottom,#eeeeee,#e0e0e0);
    background-image: -ms-linear-gradient(to bottom,#eeeeee,#e0e0e0);
    background-image: -o-linear-gradient(to bottom,#eeeeee,#e0e0e0);
    background-image: linear-gradient(to bottom,#eeeeee,#e0e0e0);
    border: 1px solid #cccccc;
    color: #333333;
   }
   .anychart-button-standard.anychart-button-disabled {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #f5f5f5;
    background-image: -webkit-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    border: 1px solid #f3f3f3;
    border: 1px solid rgba(0,0,0,0.05);
    color: #b8b8b8;
    cursor: default;
   }
   .anychart-button-toggle {
    height: 28px;
    line-height: 24px;
    padding: 0;
    min-width: 27px;
    margin: 0;
    vertical-align: middle;
   }
   .anychart-button.anychart-button-toggle {
    z-index: auto;
   }
   .anychart-button-collapse-left,
   .anychart-button-collapse-right {
    z-index: 1;
   }
   .anychart-button-collapse-left.anychart-button-checked,
   .anychart-button-collapse-right.anychart-button-checked {
    z-index: 2;
   }
   .anychart-button-collapse-left:hover,
   .anychart-button-collapse-right:hover {
    z-index: 3;
   }
   .anychart-button-collapse-left.anychart-button-disabled {
    z-index: 0;
   }
   .anychart-button-collapse-right {
    margin-right: 0;
    -moz-border-radius-topright: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
   }
   .anychart-button-collapse-left {
    margin-left: -1px;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topleft: 0;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
   }
   .anychart-menu {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    background: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0,0,0,.2);
    cursor: default;
    font-size: 13px;
    color: #222222;
    font-family: Arial, sans-serif;
    margin: 0;
    outline: none;
    padding: 6px 0;
    position: absolute;
    z-index: 1003;
    line-height: normal;
   }
   .anychart-menuitem {
    position: relative;
    color: #333333;
    cursor: pointer;
    list-style: none;
    margin: 0;
    padding: 6px 1em 6px 30px;
    white-space: nowrap;
   }
   .anychart-menuitem.anychart-menuitem-rtl {
    padding-left: 7em;
    padding-right: 28px;
   }
   .anychart-menu-nocheckbox .anychart-menuitem,
   .anychart-menu-noicon .anychart-menuitem {
    padding-left: 12px;
   }
   .anychart-menu-noaccel .anychart-menuitem {
    padding-right: 20px;
   }
   .anychart-menuitem-disabled {
    cursor: default;
   }
   .anychart-menuitem-disabled .anychart-menuitem-accel,
   .anychart-menuitem-disabled .anychart-menuitem-content {
    color: #cccccc !important;
   }
   .anychart-menuitem-disabled .anychart-menuitem-icon {
    opacity: 0.3;
    -moz-opacity: 0.3;
    filter: alpha(opacity=30);
   }
   .anychart-menuitem-highlight,
   .anychart-menuitem-hover {
    background-color: #eeeeee;
    border-color: #eeeeee;
    border-style: dotted;
    border-width: 1px 0;
    padding-top: 5px;
    padding-bottom: 5px;
   }
   .anychart-menuitem-checkbox,
   .anychart-menuitem-icon {
    background-repeat: no-repeat;
    height: 21px;
    left: 3px;
    position: absolute;
    right: auto;
    top: 3px;
    vertical-align: middle;
    width: 21px;
   }
   .anychart-menuitem i {
    position: absolute;
    left: 9px;
   }
   .anychart-menuitem-link {
    padding: 0;
   }
   .anychart-menuitem-link a {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    padding: 6px 3em 6px 28px;
    width: 100%;
    box-sizing: border-box;
    transition: none;
   }
   .anychart-menuitem-link i {
    padding-top: 7px;
    pointer-events: none;
   }
   .anychart-menuitem-link.anychart-menuitem-highlight a {
    padding-bottom: 5px;
    padding-top: 5px;
   }
   .anychart-menuitem-link.anychart-menuitem-highlight i {
    padding-top: 6px;
   }
   .anychart-menuitem-rtl .anychart-menuitem-checkbox,
   .anychart-menuitem-rtl .anychart-menuitem-icon {
    left: auto;
    right: 6px;
   }
   .anychart-menuitem-accel {
    color: #777777;
    direction: ltr;
    left: auto;
    float: right;
    padding: 0 0 0 24px;
    position: relative;
    right: 0;
    text-align: right;
   }
   .anychart-menuitem-rtl .anychart-menuitem-accel {
    left: 0;
    right: auto;
    text-align: left;
   }
   .anychart-menuitem-mnemonic-hint {
    text-decoration: underline;
   }
   .anychart-menuitem-mnemonic-separator {
    color: #999999;
    font-size: 12px;
    padding-left: 4px;
   }
   .anychart-menuseparator {
    border-top: 1px solid #ebebeb;
    margin-top: 6px;
    margin-bottom: 6px;
   }
   .anychart-submenu-arrow {
    color: #000000;
    opacity: .5;
    filter: alpha(opacity=50);
    position: absolute;
    right: -6px;
    top: 3px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #000000;
    font-size: 0;
   }
   .anychart-menuitem-content {
    position: relative;
   }
   .anychart-menuitem-highlight .anychart-submenu-arrow,
   .anychart-menuitem-hover .anychart-submenu-arrow {
    border-left-color: #333333;
    opacity: 1.0;
   }
   .anychart-menuitem-rtl .anychart-submenu-arrow {
    text-align: left;
    left: 0;
    right: auto;
    padding-left: 6px;
   }
   .anychart-menuitem-disabled .anychart-submenu-arrow {
    border-left-color: #cccccc;
    opacity: 1.0;
   }
   .anychart-menu-scrollable {
    overflow-y: auto;
   }
   .anychart-menu.anychart-menu-horizontal {
    padding-left: 4px;
    padding-right: 4px;
   }
   .anychart-menu.anychart-menu-horizontal .anychart-menuitem {
    display: inline-block;
    padding: 2px 3px;
   }
   .anychart-menu.anychart-menu-horizontal .anychart-menuitem.anychart-menuitem-highlight,
   .anychart-menu.anychart-menu-horizontal .anychart-menuitem .anychart-menuitem-hover {
    padding-top: 1px;
    padding-bottom: 1px;
   }
   .anychart-menuitem.anychart-option i {
    position: relative;
    left: 1px;
    height: 23px;
    padding-top: 1px;
    width: 23px;
   }
   .anychart-menuitem.anychart-option i.ac-position-center {
    top: -1px;
   }
   .anychart-menu-button {
    background: #dddddd url("https://cdn.anychart.com/ACDVF/button-bg.png") repeat-x top left;
    border: 0;
    color: #000000;
    cursor: pointer;
    list-style: none;
    margin: 2px;
    outline: none;
    padding: 0;
    text-decoration: none;
    vertical-align: middle;
   }
   .anychart-menu-button-outer-box,
   .anychart-menu-button-inner-box {
    border-style: solid;
    border-color: #aaaaaa;
    vertical-align: top;
   }
   .anychart-menu-button-outer-box {
    margin: 0;
    border-width: 1px 0;
    padding: 0;
   }
   .anychart-menu-button-inner-box {
    margin: 0 -1px;
    border-width: 0 1px;
    padding: 3px 4px;
   }
   * html .anychart-menu-button-inner-box {
    left: -1px;
   }
   * html .anychart-menu-button-rtl .anychart-menu-button-outer-box {
    left: -1px;
    right: auto;
   }
   * html .anychart-menu-button-rtl .anychart-menu-button-inner-box {
    right: auto;
   }
   * :first-child + html .anychart-menu-button-inner-box {
    left: -1px;
   }
   * :first-child + html .anychart-menu-button-rtl .anychart-menu-button-inner-box {
    left: 1px;
    right: auto;
   }
   ::root .anychart-menu-button,
   ::root .anychart-menu-button-outer-box,
   ::root .anychart-menu-button-inner-box {
    line-height: 0;
   }
   ::root .anychart-menu-button-caption,
   ::root .anychart-menu-button-dropdown {
    line-height: normal;
   }
   .anychart-menu-button-disabled {
    background-image: none !important;
    opacity: 0.3;
    -moz-opacity: 0.3;
    filter: alpha(opacity=30);
    cursor: default;
   }
   .anychart-menu-button-disabled .anychart-menu-button-outer-box,
   .anychart-menu-button-disabled .anychart-menu-button-inner-box,
   .anychart-menu-button-disabled .anychart-menu-button-caption,
   .anychart-menu-button-disabled .anychart-menu-button-dropdown {
    color: #333333 !important;
    border-color: #999999 !important;
   }
   * html .anychart-menu-button-disabled {
    margin: 2px 1px !important;
    padding: 0 1px !important;
   }
   * :first-child + html .anychart-menu-button-disabled {
    margin: 2px 1px !important;
    padding: 0 1px !important;
   }
   .anychart-menu-button-hover .anychart-menu-button-outer-box,
   .anychart-menu-button-hover .anychart-menu-button-inner-box {
    border-color: #99ccff #6699ee #6699ee #77aaff !important;
   }
   .anychart-menu-button-active,
   .anychart-menu-button-open {
    background-color: #bbbbbb;
    background-position: bottom left;
   }
   .anychart-menu-button-focused .anychart-menu-button-outer-box,
   .anychart-menu-button-focused .anychart-menu-button-inner-box {
    border-color: orange;
   }
   .anychart-menu-button-caption {
    padding: 0 4px 0 0;
    vertical-align: top;
   }
   .anychart-menu-button-dropdown {
    height: 15px;
    width: 7px;
    background: url("https://cdn.anychart.com/ACDVF/editortoolbar.png") no-repeat -388px 0;
    vertical-align: top;
   }
   .anychart-menu-button-collapse-right,
   .anychart-menu-button-collapse-right .anychart-menu-button-outer-box,
   .anychart-menu-button-collapse-right .anychart-menu-button-inner-box {
    margin-right: 0;
   }
   .anychart-menu-button-collapse-left,
   .anychart-menu-button-collapse-left .anychart-menu-button-outer-box,
   .anychart-menu-button-collapse-left .anychart-menu-button-inner-box {
    margin-left: 0;
   }
   .anychart-menu-button-collapse-left .anychart-menu-button-inner-box {
    border-left: 1px solid #ffffff;
   }
   .anychart-menu-button-collapse-left.anychart-menu-button-checked.anychart-menu-button-inner-box {
    border-left: 1px solid #dddddd;
   }
   .anychart-flat-menu-button {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background-color: #f5f5f5;
    background-image: -webkit-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    border: 1px solid #dcdcdc;
    color: #333333;
    cursor: pointer;
    font-size: 11px;
    font-weight: bold;
    line-height: 27px;
    list-style: none;
    margin: 0 2px;
    min-width: 46px;
    outline: none;
    padding: 0 18px 0 6px;
    text-align: center;
    text-decoration: none;
   }
   .anychart-flat-menu-button-disabled {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #f5f5f5;
    background-image: -webkit-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    background-image: linear-gradient(to bottom,#f5f5f5,#f1f1f1);
    border: 1px solid #f3f3f3;
    border: 1px solid rgba(0,0,0,0.05);
    color: #b8b8b8;
    cursor: default;
   }
   .anychart-flat-menu-button-disabled .anychart-flat-menu-button-dropdown {
    border-color: #b8b8b8 transparent;
   }
   .anychart-flat-menu-button-hover {
    background-color: #f8f8f8;
    background-image: -webkit-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.1);
    -moz-box-shadow: 0 1px 1px rgba(0,0,0,.1);
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
    border-color: #c6c6c6;
    color: #111111;
   }
   .anychart-flat-menu-button.anychart-flat-menu-button-open,
   .anychart-flat-menu-button.anychart-flat-menu-button-active {
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    background-color: #eeeeee;
    background-image: -webkit-linear-gradient(to bottom,#eeeeee,#e0e0e0);
    background-image: -moz-linear-gradient(to bottom,#eeeeee,#e0e0e0);
    background-image: -ms-linear-gradient(to bottom,#eeeeee,#e0e0e0);
    background-image: -o-linear-gradient(to bottom,#eeeeee,#e0e0e0);
    background-image: linear-gradient(to bottom,#eeeeee,#e0e0e0);
    border: 1px solid #cccccc;
    color: #333333;
    z-index: 2;
   }
   .anychart-flat-menu-button-focused {
    border-color: #4d90fe;
   }
   .anychart-flat-menu-button-caption {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    padding-right: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
   }
   .anychart-flat-menu-button-dropdown {
    border-color: #777777 transparent;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    height: 0;
    width: 0;
    position: absolute;
    right: 5px;
    top: 12px;
   }
   .anychart-flat-menu-button-active .anychart-flat-menu-button-dropdown,
   .anychart-flat-menu-button-open .anychart-flat-menu-button-dropdown,
   .anychart-flat-menu-button-selected .anychart-flat-menu-button-dropdown,
   .anychart-flat-menu-button-hover .anychart-flat-menu-button-dropdown {
    border-color: #595959 transparent;
   }
   .anychart-combobox {
    background: 0;
    background-color: whiteSmoke;
    background-image: -webkit-linear-gradient(to bottom,whiteSmoke,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,whiteSmoke,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,whiteSmoke,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,whiteSmoke,#f1f1f1);
    background-image: linear-gradient(to bottom,whiteSmoke,#f1f1f1);
    border: 1px solid gainsboro;
    border: 1px solid rgba(0,0,0,0.1);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    font: normal small arial, sans-serif;
    height: 24px;
    color: #333333;
    line-height: 24px;
    list-style: none;
    font-size: 11px;
    font-weight: bold;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 4px;
    padding: 1px 0;
    top: auto;
   }
   .anychart-combobox:hover {
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.1);
    -moz-box-shadow: 0 1px 1px rgba(0,0,0,.1);
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
    background-color: #f8f8f8;
    background-image: -webkit-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    background-image: linear-gradient(to bottom,#f8f8f8,#f1f1f1);
    border-color: #c6c6c6;
    color: #222222;
   }
   .anychart-combobox:hover input {
    border-right-color: #d9d9d9;
   }
   .anychart-combobox.anychart-combobox-disabled {
    background-color: whiteSmoke;
    background-image: -webkit-linear-gradient(to bottom,whiteSmoke,#f1f1f1);
    background-image: -moz-linear-gradient(to bottom,whiteSmoke,#f1f1f1);
    background-image: -ms-linear-gradient(to bottom,whiteSmoke,#f1f1f1);
    background-image: -o-linear-gradient(to bottom,whiteSmoke,#f1f1f1);
    background-image: linear-gradient(to bottom,whiteSmoke,#f1f1f1);
    border: 1px solid #f2f2f2;
    opacity: 1;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    cursor: default;
   }
   .anychart-combobox.anychart-combobox-disabled input {
    color: #b8b8b8;
    border-right-color: transparent;
   }
   .anychart-combobox.anychart-combobox-disabled .anychart-combobox-button {
    opacity: 0.4;
   }
   .anychart-combobox input {
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    background: transparent;
    border: 1px solid transparent;
    color: #333333;
    font-family: Arial, sans-serif;
    font-size: 11px;
    font-weight: bold;
    height: 20px;
    overflow: hidden;
    padding: 0 0 0 3px;
    position: relative;
    margin-right: 18px;
   }
   .anychart-combobox input:focus {
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.3);
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.3);
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.3);
    -webkit-user-select: text;
    -moz-user-select: text;
    background: #ffffff;
    border: 1px solid #4d90fe;
    height: 20px;
    outline: none;
   }
   .anychart-combobox-button {
    display: inline-block;
    border-color: #777777 transparent;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    height: 0;
    width: 0;
    position: absolute;
    right: 5px;
    top: 12px;
   }
   .anychart-checkbox {
    cursor: pointer;
    display: inline-block;
    margin: 2px 2px 2px 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
   }
   .anychart-checkbox .anychart-checkbox-element {
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    background-color: rgba(255,255,255,.05);
    border: 1px solid #c6c6c6;
    border: 1px solid rgba(155,155,155,.57);
    height: 11px;
    margin: 0 4px 1px 1px;
    outline: 0;
    vertical-align: text-bottom;
    width: 11px;
   }
   .anychart-checkbox-hover .anychart-checkbox-element {
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.1);
    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.1);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.1);
    border: 1px solid #b2b2b2;
   }
   .anychart-checkbox-focused .anychart-checkbox-element {
    border: 1px solid #4d90fe;
   }
   .anychart-checkbox-checked {
    background-color: #ffffff;
    background-color: rgba(255,255,255,.65);
   }
   .anychart-checkbox-checked .anychart-checkbox-checkmark {
    display: inline-block;
   }
   .anychart-checkbox-disabled {
    cursor: default;
    color: #b8b8b8;
   }
   .anychart-checkbox-disabled .anychart-checkbox-element {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
    cursor: default;
   }
   .anychart-checkbox-checkmark {
    display: none;
    opacity: 0.6;
    height: 15px;
    outline: 0;
    width: 15px;
    left: 0;
    position: relative;
    top: -3px;
   }
   .anychart-palette {
    cursor: default;
    outline: none;
   }
   .anychart-palette-table {
    empty-cells: show;
    margin: 16px;
   }
   .anychart-palette-cell {
    border: 1px solid transparent;
    cursor: pointer;
    margin: 0;
    position: relative;
   }
   .anychart-plot-controls {
    position: absolute;
   }
   .anychart-plot-controls .anychart-button {
    min-width: 20px;
    height: 20px;
    opacity: 0.7;
    line-height: 0;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    display: inline-block;
   }
   .anychart-plot-controls-hidden {
    visibility: hidden;
   }
   .anychart-palette-colorswatch {
    border: none;
    font-size: 14px;
    height: 16px;
    position: relative;
    width: 16px;
    display: block;
   }
   .anychart-palette-colorswatch:before {
    display: none;
    position: relative;
    top: 1px;
    left: 2px;
   }
   .anychart-palette-cell-hover {
    border: 1px solid #000000;
   }
   .anychart-palette-cell-selected {
    outline: 1px solid #000000;
   }
   .anychart-palette-cell-selected .anychart-palette-colorswatch:before {
    display: inline-block;
   }
   .anychart-color-menu-button-indicator {
    height: 14px;
    margin-left: 4px;
    outline: 1px solid #bbbbbb;
    width: 14px;
   }
   .anychart-color-menu-button .anychart-menu-button-inner-box,
   .anychart-toolbar-color-menu-button .anychart-toolbar-menu-button-inner-box {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
   }
   .anychart-toolbar {
    background-color: #f7f7f7;
    border: 1px solid #d5d5d5;
    cursor: default;
    font: normal 12px Verdana, sans-serif;
    color: #7c868e;
    padding: 2px;
    position: relative;
   }
   .anychart-toolbar-button,
   .anychart-toolbar-menu-button {
    margin: 0 2px;
    border: 0;
    padding: 2px 2px;
    text-decoration: none;
    vertical-align: middle;
    list-style: none;
    cursor: default;
    outline: none;
   }
   .anychart-toolbar-button-inner-box,
   .anychart-toolbar-menu-button-inner-box {
    padding: 3px 4px;
   }
   .anychart-toolbar-button-hover,
   .anychart-toolbar-menu-button-hover {
    background-color: #eeeeee;
   }
   .anychart-toolbar-menu-button-active,
   .anychart-toolbar-menu-button-open,
   .anychart-toolbar-button-active,
   .anychart-toolbar-button-checked,
   .anychart-toolbar-button-selected {
    background-color: #dddddd !important;
   }
   .anychart-toolbar-menu-button-dropdown {
    margin-left: 3px;
    padding-bottom: 1px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #7c868e;
    vertical-align: middle;
   }
   .anychart-toolbar-separator {
    margin: 0 2px;
    border-left: 1px solid #d6d6d6;
    border-right: 1px solid #f7f7f7;
    padding: 0;
    width: 0;
    text-decoration: none;
    list-style: none;
    outline: none;
    vertical-align: middle;
    line-height: normal;
    font-size: 120%;
    overflow: hidden;
   }
   .anychart-toolbar-item-icon {
    padding: 0 2px;
    font-size: 13px;
    color: #2485d0;
   }
   .anychart-toolbar-item-text {
    padding: 0 2px;
   }
   .anychart-toolbar-menu {
    background-color: #f7f7f7;
    border: 1px solid #d5d5d5;
    cursor: default;
    font: normal 12px Verdana, sans-serif;
    color: #7c868e;
    margin: 0;
    outline: none;
    padding: 6px 0;
    position: absolute;
    z-index: 1003;
    line-height: normal;
   }
   .anychart-toolbar-menu .anychart-menuitem {
    color: #7c868e;
   }
   .anychart-toolbar-menu-button-hover .anychart-toolbar-menu-button-dropdown,
   .anychart-toolbar-menu-button-open .anychart-toolbar-menu-button-dropdown {
    border-top: 4px solid #000000;
   }
   .anychart-toolbar-menu .anychart-menuitem i,
   .anychart-toolbar-menu .anychart-menuitem-content {
    display: inline;
    position: static;
   }
   .anychart-toolbar-menu .anychart-menuitem {
    padding: 6px 5px;
    padding-right: 8px;
    border-style: none;
   }
   .anychart-toolbar-menu .anychart-submenu-arrow {
    position: absolute;
    right: 0;
    top: 9px;
    padding: 0 2px;
   }
   .anychart-menuitem-content {
    margin-right: 1rem;
   }
   .anychart-option[role='menuitemradio'] {
    padding-left: 1.5rem;
   }
   .anychart-option-selected .anychart-menuitem-checkbox:before {
    content: '\2713';
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
   }
   .anychart-range-picker {
    font-family: Arial, sans-serif;
    font-size: 11px;
    color: #7c868e;
   }
   .anychart-range-picker.anychart-range-picker-inside {
    position: absolute;
    bottom: 27px;
    left: 20px;
   }
   .anychart-range-picker .anychart-label-input {
    font-size: 11px;
    margin: 0 5px;
    height: 13px;
    padding: 3px 4px;
    width: 90px;
    font-weight: normal;
   }
   .anychart-range-picker .anychart-input-label {
    margin-bottom: 0;
    font-weight: normal;
   }
   .anychart-range-selector {
    font-family: Arial, sans-serif;
    font-size: 11px;
    color: #7c868e;
   }
   .anychart-range-selector.anychart-range-selector-inside {
    position: absolute;
    bottom: 27px;
    right: 25px;
   }
   .anychart-range-selector .anychart-input-label {
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 0;
    font-weight: normal;
   }
   .anychart-range-selector .anychart-button {
    height: 21px;
    line-height: 19px;
    padding: 0 5px;
   }
   .anychart-zoom {
    position: absolute;
    left: 10px;
    top: 10px;
   }
   .anychart-zoom .anychart-button {
    height: 21px;
    width: 21px;
    min-width: 21px;
    padding: 0;
    line-height: normal;
    display: block;
    margin: 5px 0;
   }
   .anychart-zoom .anychart-zoom-zoomIn {
    margin: 2px 0;
   }
   .anychart-zoom .anychart-zoom-zoomOut {
    margin: 2px 0;
   }
   .disable-selection {
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
   }
   .anychart-tooltip {
    border-radius: 3px;
    padding: 5px 10px;
    background: rgba(33,33,33,0.7);
    border: none;
    display: inline-block;
    box-sizing: border-box;
    letter-spacing: normal;
    color: #ffffff;
    font-family: Helvetica, 'sans-serif';
    font-size: 12px;
    position: absolute;
    pointer-events: none;
    margin: 10px 0px 10px 10px;
   }
   .anychart-tooltip-separator {
    color: rgba(206,206,206,0.3);
    border: none;
    height: 1px;
    margin: 5px 0;
   }
   .anychart-tooltip-title {
    font-size: 14px;
   }