#ActivityModal {

	.candidate {
		background: #F8F8F9;
		border-radius: 4px;
		padding: 20px 16px;
	}

	.activity {
		position: relative;

		.timeline {
			width: 1px;
			background: #DEDEDE;
			position: absolute;
			height: 98%;
			left: 15px;
		}

		.attendee {
			margin-bottom: 8px;
			img {
				width: 24px;
			}
		}

		.item {
			margin-bottom: 34px;
			.feedback {
				margin-top: 12px;
				background: #F8F8F9;
				border-radius: 4px;
				padding: 12px 16px;

				img {
					margin-right: 14px;
					margin-top: -4px;
				}
			}
			.highlighted {
				border: 2px solid #16E7CF;
			}
		}
	}
}


button.star_selector {
	background: transparent;
	border: 0;
	padding: 0;
}

.card-error {
	background: rgba(235, 124, 99, 0.1);
	border: 1px solid #EB7C63;
	border-radius: 4px;
	padding: 12px;

	p {
		font-size: 14px;
	}

	img {
		margin-left: 12px;
	}

}
