// Status Items

@mixin statusolid {
	padding: 6px 12px;
	border-radius: 20px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #211600;
	display: inline-block;
	margin-right: 8px;
}
@mixin statusolidsquare {
	padding: 4px 8px;
	border-radius: 4px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #211600;
	display: inline-block;
	margin-right: 8px;
}

// Common properties
$flag-width: 34px;
$flag-height: 22px;

// Two halfs flag
@function halfs($first, $second, $or: -180deg){
	@return linear-gradient($or, $first 50%, $second 50%);
}


%flag{
	width: $flag-width;
	height: $flag-height;
	border: 1px solid #999;
	display: inline-block;
	margin: 8px;
	box-shadow: 1px 1px 1px #CCC;
	transition: all .5s;
	margin-bottom: 1px;
	margin-top: -5px;
	position: relative;
	top: 6px;
	margin-left: 1px;

}
//Exceptions (not functions)
.flag--ukraine{
	@extend %flag;
	background: halfs(royalblue, yellow);
}

.client-modal-styling{
	display: none;
	font-weight: bolder;
}

.yellow_solid {
	background: rgba(255, 170, 0, 0.24);
	@include statusolid;
}

.grey_solid {
	background: #F0F0F0;
	@include statusolid;
}

.green_solid {
	background: rgba(22, 231, 207, 0.27);
	@include statusolid;
}

.blue_solid {
	background: rgba(0, 128, 255, 0.24);
	@include statusolid;
}

.primary_solid {
	background: #131936;
	@include statusolid;
	color: #fff!important;
}

.red_solid {
	background: #FFC6C2;
	@include statusolid;
}

.yellow_solid_square {
	background: rgba(255, 170, 0, 0.24);
	@include statusolidsquare;
}

.grey_solid_square {
	background: #F0F0F0;
	@include statusolidsquare;
}

.grey_location_square {
	background: #cccccc;
	padding: 4px 8px;
	width: 90%;
	border-radius: 4px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #211600;
	display: inline-block;
	margin-right: 8px;
}

.green_solid_square {
	background: rgba(22, 231, 207, 0.27);
	@include statusolidsquare;
}

.blue_solid_square {
	background: #CCEEFF;
	@include statusolidsquare;
}

.primary_solid_square {
	background: #131936;
	@include statusolidsquare;
	color: #fff!important;
}

.red_solid_square {
	background: #FFC6C2;
	@include statusolidsquare;
}

@mixin statusstroke {
	padding: 12px 6px;
	border-radius: 20px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #211600;
	display: inline-block;
}

.yellow_stroke {
	border: 1px solid #FFAA00;
	@include statusstroke;
}

.grey_stroke {
	border: 1px solid #D6D5D5;
	@include statusstroke;
}

.green_stroke {
	border: 1px solid #16E7CF;
	@include statusstroke;
}

.blue_stroke {
	border: 1px solid #00D4FF;
	@include statusstroke;
}

.red_stroke {
	border: 1px solid #333333;
	@include statusstroke;
}

.radius_sm {
	border-radius: 2px;
}


// Buttons

.btn_solid {
	display: inline-block;
	background: theme-color(secondary);
	border-radius: 4px;
	padding: 7px 16px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	transition: 0.3s;
	color: white;
	border: none;
	border: 2px solid theme-color(secondary);

	&:hover {
		background: theme-color(secondary-hover);
		border: 2px solid theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.hide_link {
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	color: #004C7F;
	margin-right: 24px;
}

.hide_link_blue {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
    margin-right: 35px;
	line-height: 45px;

	&:hover {
		color: #efefef;
		cursor: pointer;
	}

}

.btn_stroke {

	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(secondary);
	border-radius: 4px;
	padding: 10px 16px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	transition: 0.3s;
	color: theme-color(secondary);


	&:hover {
		background: theme-color(secondary-hover);
		border: 2px solid theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

@mixin tags {
	padding: 2px 12px;
	border-radius: 20px;
	margin-right: 8px;
	font-size: 14px;

	@media (max-width: 667px) {
		margin-bottom: 8px;
	}
}

.tag_grey {
	background: #DEDEDE;
	@include tags;
	color: #131936;
}

.tag_primary {
	background: #131936;
	@include tags;
	color: white;
}

.request_tag_grey {
	border: 1px solid #8BAED5;
	@include tags;
	color: #333;
}

.request_tag_primary {
	border: 1px solid #131936;
	@include tags;
	color:#333;
}


// Tables
.table-responsive {
	padding-right: 36px;
	padding-left: 36px;
}
.table thead th {
	border-top: none;
}
.table {
	color: black;
	thead {

		th {
			font-family: Helvetica;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			color: black;
			padding-left: 0;
		}

	}

	tbody {
		td {
			font-family: Helvetica;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: black;
			border: none!important;
			padding-left: 0;
		}
		th {
			border: none!important;
			padding-left: 0;
		}
	}
}

.dropdown {
	.dropdown-item {
		color: #000000;
		font-family: Helvetica;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
	}
}


.addteammodal {
	.date {
		width: 197px;
	}
}


.status_tag_primary {
	padding: 4px 8px;
	font-size: 14px;
	font-weight: normal;
	color: white;
	background: #131936;
	border: 1px solid #131936;
	border-radius: 4px;
	margin-left: 10px;
}

.status_tag_primary_outline {
	padding: 4px 8px;
	font-size: 14px;
	font-weight: normal;
	color: #131936;
	border: 1px solid #131936;
	background: transparent;
	border-radius: 4px;
	margin-left: 10px;
}

.status_tag_grey {
	padding: 4px 8px;
	font-size: 12px;
	font-weight: normal;
	color: #131936;
	background: #E1E4EC;
	border-radius: 4px;
	display: inline-block;
}

.status_tag_grey_stroke {
	padding: 4px 8px;
	font-size: 12px;
	font-weight: normal;
	color: #131936;
	border: 1px solid #E1E4EC;
	border-radius: 4px;
	display: inline-block;
}

.td-none:hover {
	text-decoration: none;
}

.tag_blue {
	padding: 4px 8px;
    font-size: 14px;
    font-weight: normal;
    color: #333333;
    background: #E1E4EC;
    border-radius: 4px;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 0 !important;
}


.add_button_modal {
	svg {
		margin-top: -3px;
		margin-right: 8px;
	}

	&:hover {
		svg path {
			stroke: #fff;
		}
	}
}


.mb-12px {
	margin-bottom: 12px;
}
.text-33 {
	color: #333;
}
.util_gear {
	img {
		margin-right: 4px;
	}
}

.btn_stroke:hover {
	path {
		stroke: white!important;
	}
}
