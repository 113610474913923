ul.styled_list {

	list-style: none;
	padding-left: 20px;

	li {
		margin-bottom: 16px;
	}

	li::before {
		content: "\2022";
		color: #3279C6;
		font-weight: bold;
		display: inline-block;
		width: 15px;
		margin-left: -15px;
		line-height: 20px;
		font-size: 25px;
		vertical-align: middle;
	}

}
