.legal_page {

	h4 {
		color: theme-color(primary);
		font-weight: bold;
		font-size: 32px;
		line-height: 48px;
		margin-bottom: 14px;
		margin-top: 24px;
	}

	p {
		margin-bottom: 16px;
		strong	{
			color: theme-color(primary);
		}
	}

	.text-secondary {
		color: #3279C6!important;
	}

	ul li {
		margin-bottom: 16px;
	}

	ul {
		margin-bottom: 16px;
	}

}
