.alert-success {
    color: #2d6f3c;
    border-color: #16E7CF;
    background: rgba(22, 231, 207, 0.27);
    border: 1px solid #16E7CF;
    color: #131936;
    font-size: 16px;
}

.alert-accent {
    background: rgba(204, 238, 255, 0.5);
    border: 1px solid #3279C6;
    color: #131936;
    font-size: 14px;
}


.swal2-icon.swal2-success [class^='swal2-success-line'] {
    height: 3px!important;
    background-color: #a5dc86;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
}


.swal2-icon.swal2-success [class^='swal2-success-line'][class$='tip'] {
    width: 15px!important;
    left: 4px!important;
    top: 24px!important;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='long'] {
    width: 25px!important;
    right: 3px!important;
    top: 20px!important;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.swal2-icon.swal2-success .swal2-success-fix {
    width: 7px!important;
    height: 20px!important;
    position: absolute;
    left: 28px!important;
    top: 8px!important;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    display: none!important;
}

.swal2-icon.swal2-success {
    border-color: #3278c6 !important;
    color: #3278c6 !important;
}

.swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='left'] {
    display: none!important;
}

.swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='right'] {
    display: none!important;
}

.alert-explain {
    padding: 4px 8px 4px 8px !important;
    display: inline-block;
}