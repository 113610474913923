header {
	border-bottom: 1px solid #D6D5D5;
	background: white;
}

header-plain {
	background: white;
}

.progress_header {
	background: white;
}

.progress-offset {
	top: 57px;
}

.main-offset {
	margin-top: 140px;

	@media (max-width:667px) {
		margin-top: 80px;
	}
}

.portal-offset {
	margin-top: 90px;
}

.navbar {
	padding-left: 0;
	padding-right: 0;
}

.pre_nav {
	padding-top: 16px;
	padding-bottom: 28px;


	.nav-item {
		padding-top: 0;
		padding-bottom: 0;

		@media (max-width: 992px) {
			margin-right: 0!important;
		}
	}

	.nav-item {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.2px;
		color: theme-color(secondary);
		margin-right: 22px;
		font-style: normal;
		font-weight: normal;
		transition: 0.4s;
		position: relative;
		&:after {
			transition: 0.3s;
			opacity: 0;
			content: '';
		}
		@media (max-width: 992px) {
			margin-right: 0!important;
		}
	}

	.nav-item.active {
		color: theme-color(primary);
		font-style: normal;
		font-weight: bold;
	}

	.nav-item:hover {
		color: theme-color(primary);
		font-style: normal;

		&:after {
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			background: #16E7CF;
			border-radius: 4px;
			margin: auto;
			margin-top: 4px;
			position: absolute;
			left: 0;
			right: 0;
			opacity: 1;
		}

	}

	.mail_icon {
		margin-right: 5px;
	}

}

.admin_pre {
	padding-top: 0px;
	padding-bottom: 0px;


	.nav-item {
		padding-top: 0;
		padding-bottom: 0;

		@media (max-width: 992px) {
			margin-right: 0!important;
		}
	}

	.nav-item {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.2px;
		color: #004C7F;
		margin-right: 22px;
		font-style: normal;
		font-weight: normal;
		transition: 0.4s;
		position: relative;
		&:after {
			transition: 0.3s;
			opacity: 0;
			content: '';
		}
		@media (max-width: 992px) {
			margin-right: 0!important;
		}
	}

	.nav-item.active {
		color: theme-color(primary);
		font-style: normal;
		font-weight: bold;
	}

	.nav-item:hover {
		color: theme-color(primary);
		font-style: normal;

		&:after {
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			background: #16E7CF;
			border-radius: 4px;
			margin: auto;
			margin-top: 4px;
			position: absolute;
			left: 0;
			right: 0;
			opacity: 1;
		}

	}

	.mail_icon {
		margin-right: 5px;
	}

}

.main_nav {
	padding-bottom: 25px;

	@media (max-width: 992px) {
		padding-top: 12px;
		padding-bottom: 12px;
	}
}

.main_nav {

	.navbar-brand {
		margin-right: 70px;
	}

	.nav-item {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.2px;
		color: theme-color(secondary);
		margin-right: 22px;
		font-style: normal;
		font-weight: normal;
		transition: 0.4s;
		position: relative;
		&:after {
			transition: 0.3s;
			opacity: 0;
			content: '';
		}
		@media (max-width: 992px) {
			margin-right: 0!important;
		}
	}

	.nav-item.active {
		color: theme-color(primary);
		font-style: normal;
		font-weight: bold;
	}

	.nav-item:hover {
		color: theme-color(primary);
		font-style: normal;

		&:after {
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			background: #16E7CF;
			border-radius: 4px;
			margin: auto;
			margin-top: 4px;
			position: absolute;
			left: 0;
			right: 0;
			opacity: 1;
		}

	}

}

// Nav Button

.nav_btn {
	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(accent);
	border-radius: 4px;
	padding: 10px 16px!important;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold!important;
	font-size: 14px;
	transition: 0.3s;
	color: theme-color(primary)!important;

	&:hover {
		background: theme-color(accent);
		border: 2px solid theme-color(accent);
		text-decoration: none;
		color: theme-color(primary);;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
	}
}

.navbar-toggler {

	&:focus, &:visited {
		outline: none;
	}

	input#menu {
		display: none;
	}

	.icon {
		cursor: pointer;
		display: block;
		height: 24px;
		width: 24px;
	}

	.icon .menu,
	.icon .menu::before,
	.icon .menu::after {
		background: $blue-100;
		content: '';
		display: block;
		height: 2px;
		position: absolute;
		transition: background ease .3s, top ease .3s .3s, transform ease .3s;
		width: 24px;
		border-radius: 35px;
		top: 30px;
	}

	.icon:hover .menu,
	.icon:hover .menu::before,
	.icon:hover .menu::after {
		background: $blue-100;
	}

	.icon .menu::before {
		top: -6px;
	}

	.icon .menu::after {
		top: 6px;
	}

	#menu:checked + .icon .menu {
		background: transparent;
	}

	#menu:checked + .icon .menu::before {
		transform: rotate(45deg);
	}

	#menu:checked + .icon .menu::after {
		transform: rotate(-45deg);
	}

	#menu:checked + .icon .menu::before,
	#menu:checked + .icon .menu::after {
		top: 0;
		transition: top ease .3s, transform ease .3s .3s;
	}

}

@media (max-width: 992px) {
	.navbar-collapse {
		padding-top: 40px;
		padding-bottom: 20%;
		text-align: center;

		.nav_btn {
			width: 100%;
		}
	}
}

@media (min-width:667px) and (max-width: 992px) {
	.nav_btn {
		width: 260px!important;
	}
}



.dashboard_nav {

	padding-top: 14px;
    padding-bottom: 13px;
	padding-left: 16px;
	padding-right: 16px;


	.nav_btn_solid {
		display: inline-block;
		border: none;

		background: theme-color(secondary);
		border-radius: 4px;
		padding: 10px 16px;

		font-family: Helvetica;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		transition: 0.3s;
		color: white;

		@media (max-width:667px) {
			border-radius: 4px;
		}

		@media (max-width: 667px) {
			font-size: 14px;
			width: 100%;
		}

		&:hover {
			background: theme-color(secondary-hover);
			text-decoration: none;
			color: white;
			box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
			cursor: pointer;
		}
	}

	.navbar-brand {
		margin-right: 70px;
	}

	.nav-item {
		position: relative;
		margin-right: 22px;

		.tag {
			background: #E0F3FF;
			color: #131936;
			font-size: 12px;
			padding: 3px 6px;
			font-weight: bold;
			border-radius: 20px;
		}
	}
	.nav-item .nav-link {
		font-size: 14;
		line-height: 20px;
		letter-spacing: 0.2px;
		color: #004C7F;
		font-style: normal;
		font-weight: normal;
		transition: 0.4s;
		position: relative;
		&:after {
			transition: 0.3s;
			opacity: 0;
			content: '';
		}
		@media (max-width: 992px) {
			margin-right: 0!important;
		}
	}

	.nav-item.active {
		color: theme-color(primary);
		.nav-link {
			color: theme-color(primary);
			font-weight: bold;
		}
		&:after {
			content: "";
			display: block;
			width: 45px;
			height: 4px;
			background: theme-color(primary);
			border-radius: 0;
			position: absolute;
			bottom: -18px;
			left: 0;
			right: 0;
			margin: auto;
			opacity: 1;

			@media (max-width: 667px) {
				display: none;
			}
		}
	}

	.nav_btn {
		padding: 10px 24px;
		font-size: 14px;
		margin-right: 22px;
	}

}


.admin_nav {

	padding-top: 14px;
    padding-bottom: 13px;
	padding-left: 16px;
	padding-right: 16px;


	.nav_btn_solid {
		display: inline-block;
		border: none;

		background: theme-color(secondary);
		border-radius: 4px;
		padding: 10px 16px;

		font-family: Helvetica;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		transition: 0.3s;
		color: white;

		@media (max-width:667px) {
			border-radius: 4px;
		}

		@media (max-width: 667px) {
			font-size: 14px;
			width: 100%;
		}

		&:hover {
			background: theme-color(secondary-hover);
			text-decoration: none;
			color: white;
			box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
			cursor: pointer;
		}
	}

	.navbar-brand {
		margin-right: 70px;
	}

	.nav-item {
		position: relative;
		margin-right: 18px;

		.tag {
			background: #E0F3FF;
			color: #131936;
			font-size: 12px;
			padding: 3px 6px;
			font-weight: bold;
			border-radius: 20px;
		}
	}
	.nav-item .nav-link {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.2px;
		color: #004C7F;
		font-style: normal;
		font-weight: normal;
		transition: 0.4s;
		position: relative;
		&:after {
			transition: 0.3s;
			opacity: 0;
			content: '';
		}
		@media (max-width: 992px) {
			margin-right: 0!important;
		}
	}

	.nav-item.active {
		color: theme-color(primary);
		.nav-link {
			color: theme-color(primary);
			font-weight: bold;
		}
		&:after {
			content: "";
			display: block;
			width: 45px;
			height: 4px;
			background: theme-color(primary);
			border-radius: 0;
			position: absolute;
			bottom: -18px;
			left: 0;
			right: 0;
			margin: auto;
			opacity: 1;

			@media (max-width: 667px) {
				display: none;
			}
		}
	}

	.nav_btn {
		padding: 10px 24px;
		font-size: 14px;
		margin-right: 22px;
	}

	.dropdown .dropdown-item {
		font-size: 14px;
		line-height: 24px;
	}

}

.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: transparent;
}
