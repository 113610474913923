.notice {
	background: rgba(235, 124, 99, 0.1);
	border: 1.5px solid #eb7c63;
	border-radius: 4px;
	padding: 12px;
	color: #131936;
	margin-top: 24px;
	margin-bottom: 40px;
}

.secondary_list {
	list-style: none;
	padding-left: 20px;

	li {
		position: relative;
		font-size: 14px;
		color: #333333;
		margin-bottom: 5px;
		&:before {
			content: "\2022";
			color: #3279c6;
			font-weight: bold;
			display: inline-block;
			width: 10px;
			margin-left: 0;
			opacity: 0.54;
			font-size: 30px;
			position: absolute;
			left: -20px;
			top: -10px;
		}
	}
}

.specialised_wrapper {
	margin-top: 16px;

	.item {
		display: inline-block;
		padding: 5px 15px;
		font-size: 14px;
		border: 1px solid rgba(50, 121, 198, 0.5);
		border-radius: 24px;
		margin-right: 10px;
		margin-bottom: 10px;
	}
}

.optional-label {
	font-size: 14px;
	font-weight: normal;
	color: #999999;
}

.dropify-wrapper {
	width: 120px !important;
	height: 120px;
	border-radius: 50%;
	margin-bottom: 20px;
	background: #90b7e0;
	float: left;
	margin-right: 20px;
}

.dropify-wrapper .dropify-preview {
	padding: 0px;
}

.avatar-butt-offset {
	margin-top: 40px;
}

.dropify-errors-container {
	/* float: right; */
	margin-top: 10px;
	display: inline-block;
	margin-left: 40px;
}

.dropify-wrapper ~ .dropify-errors-container ul li {
	background: rgba(235, 124, 99, 0.1);
	border: 1px solid #eb7c63;
	border-radius: 4px;
	padding: 20px;
	color: #131936;
	font-size: 14px;
	line-height: 20px;
	margin-left: 0px;
	font-weight: normal;
	list-style: none;
}

.dropify-wrapper.has-error {
	border-color: #eb7c63;
}

.dropify-wrapper .dropify-message {
	top: 47%;
}

.dropify-wrapper .dropify-message span.file-icon {
	font-size: 45px;
	color: #fff;
}

.dropify-wrapper .dropify-message p {
	margin: 5px 5px 0px 5px;
	font-size: 11px;
	line-height: 14px;
}

.dropify-wrapper .dropify-message p.dropify-error {
	color: #131936;
	font-weight: normal;
	display: none;
	font-size: 14px;
	line-height: 15px;
}

p.dropify-filename {
	display: none;
}

.dropify-wrapper
	.dropify-preview
	.dropify-infos
	.dropify-infos-inner
	p.dropify-infos-message {
	margin-top: 0px;
	padding-top: 0px;
	font-size: 14px;
	color: #fff;
	position: relative;
	opacity: 1;
}

.dropify-wrapper
	.dropify-preview
	.dropify-infos
	.dropify-infos-inner
	p.dropify-infos-message::before {
	display: none;
}

.dropify-wrapper .dropify-preview .dropify-infos .dropify-infos-inner p {
	white-space: normal;
	text-overflow: clip;
	color: #fff;
	text-align: center;
	line-height: 20px;
	font-weight: bold;
}

.billable-table {
	thead th {
		font-weight: normal !important;
	}
}
