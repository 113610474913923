.modal-schedule {
	max-width: 800px !important;
}

.modal-xl {
	max-width: 990px !important;
}

.modal-xxl {
	max-width: 1200px !important;
}

.modal-timsheet {
	max-width: 1180px !important;
}

.calendly-offset {
	margin-top: -10px;
}

/* SweetAlert Styling */
.swal2-container.swal2-shown {
	background: #131936d4 !important;
}

.swal2-confirm {
	font-family: Helvetica;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #004c7f !important;
	border: 2px solid #004c7f !important;
	border-radius: 6px !important;
	color: #fff !important;
	font-size: 14px !important;

	&:hover {
		background: #2b6aad !important;
		border: 2px solid #2b6aad !important;
		text-decoration: none !important;
		color: white !important;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24) !important;
	}
}

.swal2-cancel {
	font-family: Helvetica;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #fff !important;
	border-radius: 6px !important;
	color: #004c7f !important;
	font-size: 14px !important;
	border: 2px solid #004c7f !important;

	&:hover {
		text-decoration: none !important;
		color: #fff !important;
		background: #004c7f !important;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24) !important;
	}
}

.swal2-icon {
	width: 40px !important;
	height: 40px !important;
	border: 2px solid #05a4ca !important;
	margin: 20px auto 0px !important;
}

.swal2-question {
	font-family: Helvetica;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #05a4ca !important;
	border-color: #05a4ca !important;
	font-size: 30px !important;
	line-height: 40px !important;
	font-weight: bold !important;
}

.swal2-success {
	font-family: Helvetica;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #05a4ca !important;
	border-color: #05a4ca !important;
	font-size: 30px !important;
	line-height: 40px !important;
	font-weight: bold !important;
}

.swal2-success-ring {
	width: 40px !important;
	height: 40px !important;
	border: 4px solid rgba(165, 220, 134, 0.2);
}

.swal2-popup {
	.swal2-title {
		font-family: Helvetica;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #131936 !important;
		font-size: 24px !important;
		font-weight: bold !important;
	}

	.swal2-content {
		font-family: Helvetica;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 16px !important;
	}
}

.swal2-buttonswrapper {
	margin-top: 30px !important;
	padding-top: 5px !important;
}

// Bootstrap Modal

.modal-backdrop {
	background: #131936d4 !important;
}

.modal-backdrop.show {
	opacity: 1 !important;
}

/* Magnific Popup CSS */
.white-popup {
	position: relative;
	background: #fff;
	padding: 60px;
	width: 82%;
	margin: 0 auto;
	border-radius: 15px;
}

.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1042;
	overflow: hidden;
	position: fixed;
	background: #0b0b0b;
	opacity: 0.8;
}

.mfp-wrap {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1043;
	position: fixed;
	outline: none !important;
	-webkit-backface-visibility: hidden;
}

.mfp-container {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 8px;
	box-sizing: border-box;
}

.mfp-container:before {
	content: "";
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
	display: none;
}

.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto;
}

.mfp-ajax-cur {
	cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}

.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
	cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.mfp-loading.mfp-figure {
	display: none;
}

.mfp-hide {
	display: none !important;
}

.mfp-preloader {
	color: #ccc;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8em;
	left: 8px;
	right: 8px;
	z-index: 1044;
}
.mfp-preloader a {
	color: #ccc;
}
.mfp-preloader a:hover {
	color: #fff;
}

.mfp-s-ready .mfp-preloader {
	display: none;
}

.mfp-s-error .mfp-content {
	display: none;
}

button.mfp-close,
button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
	display: block;
	outline: none;
	padding: 0;
	z-index: 1046;
	box-shadow: none;
	touch-action: manipulation;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.mfp-close {
	width: 44px;
	height: 44px;
	line-height: 44px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	padding: 0 0 18px 10px;
	color: #fff;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover,
.mfp-close:focus {
	opacity: 1;
}
.mfp-close:active {
	top: 1px;
}

.mfp-close-btn-in .mfp-close {
	color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	color: #fff;
	right: -6px;
	text-align: right;
	padding-right: 6px;
	width: 100%;
}

.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #ccc;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
}

.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 90px;
	height: 110px;
	-webkit-tap-highlight-color: transparent;
}
.mfp-arrow:active {
	margin-top: -54px;
}
.mfp-arrow:hover,
.mfp-arrow:focus {
	opacity: 1;
}
.mfp-arrow:before,
.mfp-arrow:after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
	margin-top: 35px;
	margin-left: 35px;
	border: medium inset transparent;
}
.mfp-arrow:after {
	border-top-width: 13px;
	border-bottom-width: 13px;
	top: 8px;
}
.mfp-arrow:before {
	border-top-width: 21px;
	border-bottom-width: 21px;
	opacity: 0.7;
}

.mfp-arrow-left {
	left: 0;
}
.mfp-arrow-left:after {
	border-right: 17px solid #fff;
	margin-left: 31px;
}
.mfp-arrow-left:before {
	margin-left: 25px;
	border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
	right: 0;
}
.mfp-arrow-right:after {
	border-left: 17px solid #fff;
	margin-left: 39px;
}
.mfp-arrow-right:before {
	border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
	top: -40px;
}

.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #000;
}

/* Main image in popup */
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	box-sizing: border-box;
	padding: 40px 0 40px;
	margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
	line-height: 0;
}
.mfp-figure:after {
	content: "";
	position: absolute;
	left: 0;
	top: 40px;
	bottom: 40px;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #444;
}
.mfp-figure small {
	color: #bdbdbd;
	display: block;
	font-size: 12px;
	line-height: 14px;
}
.mfp-figure figure {
	margin: 0;
}

.mfp-bottom-bar {
	margin-top: -36px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
}

.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #f3f3f3;
	word-wrap: break-word;
	padding-right: 36px;
}

.mfp-image-holder .mfp-content {
	max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
	screen and (max-height: 300px) {
	/**
	* Remove all paddings around the image on small screen
	*/
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0;
	}
	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}
	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}
	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px;
	}
	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		box-sizing: border-box;
	}
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}
	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px;
	}
	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0;
	}
}

@media all and (max-width: 900px) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		transform: scale(0.75);
	}
	.mfp-arrow-left {
		-webkit-transform-origin: 0;
		transform-origin: 0;
	}
	.mfp-arrow-right {
		-webkit-transform-origin: 100%;
		transform-origin: 100%;
	}
	.mfp-container {
		padding-left: 6px;
		padding-right: 6px;
	}
}

// ====== Move-horizontal effect ======

// .mfp-move-horizontal {
// 	/* start state */
// 	/* animate in */
// 	/* animate out */
// }
.mfp-move-horizontal .mfp-with-anim {
	opacity: 0;
	transition: all 0.3s;
	transform: translateX(-50px);
}
.mfp-move-horizontal.mfp-bg {
	opacity: 0;
	transition: all 0.3s;
}
.mfp-move-horizontal.mfp-ready .mfp-with-anim {
	opacity: 1;
	transform: translateX(0);
}
.mfp-move-horizontal.mfp-ready.mfp-bg {
	opacity: 0.8;
}
.mfp-move-horizontal.mfp-removing .mfp-with-anim {
	transform: translateX(50px);
	opacity: 0;
}
.mfp-move-horizontal.mfp-removing.mfp-bg {
	opacity: 0;
}

// BS Modals

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 668px;
		margin: 1.75rem auto;
	}
}

.modal {
	@media (max-width: 667px) {
		overflow-y: scroll;
	}

	.close {
		float: right;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 1;
		color: #333333;
		text-shadow: none;
		opacity: 1;
		margin-top: -5px;
	}

	.back {
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 1;
		color: #333333;
		text-shadow: none;
		opacity: 1;
		background-color: transparent;
		border: 0;
	}

	.modal-header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 20px 30px;
		// border-bottom: none;
		border-top-left-radius: calc(0.3rem - 1px);
		border-top-right-radius: calc(0.3rem - 1px);
		margin-bottom: 20px;
		h4 {
			margin-bottom: 0 !important;
		}
	}

	.modal-body {
		padding: 0px 30px;
		padding-bottom: 20px;
	}

	.modal-footer {
		padding: 20px 30px;
	}

	.modal-logo {
		width: 90px;
		margin-top: 8px;
	}

	.btn_submit {
		@media (max-width: 667px) {
			width: 100%;
		}
	}
}

#Completed {
	h4 {
		text-align: center;
		width: 100%;
	}

	.modal-footer {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		padding: 0.75rem;
		border-top: 1px solid #dedede;
		border-bottom-right-radius: calc(0.3rem - 1px);
		border-bottom-left-radius: calc(0.3rem - 1px);
	}
}

html body .modal-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 0;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}

.add-weekly-rock{
	margin-top: -2em;
	margin-bottom: 2em;
	color: #004C7F;
	cursor: pointer;
	font-weight: bolder
}

.add-weekly-rock-manager{
	margin-top: -2em;
	margin-bottom: 2em;
	color: #004C7F;
	cursor: pointer;
	font-weight: bolder
}

.yes-no-gwc{
	position: relative;
	left: 30px;
	bottom: 7px;
}

.rock-align{
	margin-right: 53em;
}

.select-highlight{
	cursor: pointer;
}

.select-alignment{
	text-align: center;
}

.selected-red{
	border: 2px solid #e33a3a;
	border-radius: 50%;
	padding: 2px;
}


.status-buttons input[type="radio"] {
	display: none;
}


.status-buttons input[type="radio"]:checked+label {
	border: 1px solid #cccccc;
	border-radius: 50%;
	padding: 3px;
	width: 29px;
	height: 29px;
	margin-top: 2px;
}

.rocks-button{
	font-size: 13px;
}

.status-border{
	border: 1px solid #cccccc;
	padding: 12px;
	border-radius: 5px;
	margin-top: 0px;
	height: 4em;
}

.status-border-initial{
	position: relative;
	top: 7px;
}

.remove-rock{
	position: relative;
	left: 55em;
	bottom: 5.6em;
	color: red;
	cursor: pointer
}
.remove-rock-manager{
	position: relative;
	left: 71.8em;
	bottom: 5.6em;
	color: red;
	cursor: pointer

}

.rocks-margin-rows{
	margin-bottom: -2em;
}

.rocks-margin-rows-manager{
	margin-bottom: -2em;
}

.rocks-cancel{
	margin-right: 40em;
}

@media (min-width: 992px) {
	.modal-lg {
		max-width: 800px;
	}
}

.modal-footer {
	button:last-child {
		margin-left: 12px;
	}
}

#WorkingHours {
	.modal_card {
		// background: #F2F2F2;
		padding: 12px 16px;
	}
}

#clientFeedback,
#shareProfile {
	.modal-header {
		border-bottom: 0 !important;
		margin-bottom: 0;
	}
}
