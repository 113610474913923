// Employment

.employment_form,
.industry_form,
.education_form,
.passport_form,
.travelhistory_form,
.detailedexperience_form,
.references_form,
.add_referrals,
.industry_item_new {
	height: 0;
	overflow: hidden;
	transition: 0.3s;
}

.employment_form.slidein,
.industry_form.slidein,
.education_form.slidein,
.passport_form.slidein,
.travelhistory_form.slidein,
.detailedexperience_form.slidein,
.references_form.slidein,
.add_referrals.slidein,
.industry_item_new.slidein {
	height: auto;
}
