.badge-required {
    color: #fff;
    background-color: #131936;
    border-radius: 100px;
    padding: 2px 8px 2px 8px;
    font-size: 12px;
    line-height: 16px;
	height: 20px;
	font-weight: 700!important;
}

.badge-required-lg {
    color: #fff;
    background-color: #131936;
    border-radius: 100px;
    padding: 4px 10px 2px 12px;
    font-size: 14px;
    line-height: 20px;
	height: 28px;
	font-weight: 700!important;
}

.badge-percent {
    color: #131936;
    background-color: #F2F2F2;
    border-radius: 100px;
    padding: 6px 12px 6px 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
}
