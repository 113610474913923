.password_wrapper	{

	position: relative;

	p {
		color: black;
	}

	.progress {
		height: 8px;
		background-color: #DEDEDE;
		border-radius: 19px;
	}
	.progress-bar-success {
		background-color: #05A4CA!important;
	}
	.progress-bar-warning {
		background-color: #F5B83D!important;
	}
	.progress-bar-danger {
		background-color: #EB7C63!important;
	}
	.progress-bar {
		float: left;
		width: 0;
		height: 100%;
		font-size: 12px;
		line-height: 20px;
		color: #fff;
		text-align: center;
		background-color: #05A4CA;
		-webkit-transition: width .6s ease;
		-o-transition: width .6s ease;
		transition: width .6s ease;
	}

	#popover-password {
		background: #F8F8F9;
		border-radius: 4px;
		padding: 20px;
	}

	ul {
		margin-top: 12px;
		li {
			font-size: 14px;
			color: black;
			margin-bottom: 5px;

			span {
				width: 20px;
				display: inline-block;
			}
		}
	}

	.input-group-addon {
		position: absolute;
		top: 40px;
		right: 0px;
		display: block;
		width: 64px;
		height: 62px;
		text-align: right;
		padding-right: 16px;
		padding-top: 17px;
		a {
			font-size: 14px;
			font-weight: bold;
			color: #004C7F;

			&:hover {
				text-decoration: none;
			}
		}
	}
}


.password_wrapper_modal	{

	position: relative;

	p {
		color: black;
	}

	.progress {
		height: 8px;
		background-color: #DEDEDE;
		border-radius: 19px;
	}
	.progress-bar-success {
		background-color: #05A4CA!important;
	}
	.progress-bar-warning {
		background-color: #F5B83D!important;
	}
	.progress-bar-danger {
		background-color: #EB7C63!important;
	}
	.progress-bar {
		float: left;
		width: 0;
		height: 100%;
		font-size: 12px;
		line-height: 20px;
		color: #fff;
		text-align: center;
		background-color: #05A4CA;
		-webkit-transition: width .6s ease;
		-o-transition: width .6s ease;
		transition: width .6s ease;
	}

	#popover-password {
		background: #F8F8F9;
		border-radius: 4px;
		padding: 20px;
	}

	ul {
		margin-top: 12px;
		li {
			font-size: 14px;
			color: black;
			margin-bottom: 5px;

			span {
				width: 20px;
				display: inline-block;
			}
		}
	}

	.input-group-addon {
		position: absolute;
		top: 50px;
		right: 30px;
		a {
			font-size: 14px;
			font-weight: bold;
			color: #004C7F;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.referal {

	.inner-box {
		padding-left: 120px;
		padding-right: 120px;

		@media (max-width: 992px) {
			padding-left: 60px;
			padding-right: 60px;
		}

		@media (max-width: 667px) {
			padding-left: 30px;
			padding-right: 30px;
		}

		.numbered {
			width: 24px;
			height: 24px;
			display: inline-block;
			text-align: center;
			font-size: 16px;
			color: white;
			background: #131936;
			border-radius: 12px;
			margin-right: 10px;
		}

		ul.styled_list {
			list-style: none;
			padding-left: 20px;
			margin-top: 16px;

			li {
				color: black;
				font-size: 16px;
				margin-bottom: 16px;
				position: relative;

				&:before {
					content: "•";
					color: #3279C6;
					font-size: 30px;
					position: absolute;
					top: -10px;
					left: -20px;
				}
			}
		}
	}
}



.referral-signup {

	.inner-box {
		padding: 40px;

		@media (max-width: 992px) {
			padding: 40px;
		}

		@media (max-width: 667px) {
			padding: 30px;
		}

		.numbered {
			width: 24px;
			height: 24px;
			display: inline-block;
			text-align: center;
			font-size: 16px;
			color: white;
			background: #131936;
			border-radius: 12px;
			margin-right: 10px;
		}

		ul.styled_list {
			list-style: none;
			padding-left: 20px;
			margin-top: 16px;

			li {
				color: black;
				font-size: 16px;
				margin-bottom: 16px;
				position: relative;

				&:before {
					content: "•";
					color: #3279C6;
					font-size: 30px;
					position: absolute;
					top: 0px;
					left: -7px;
				}
			}
		}
	}
}

// Profile page
.avatar-upload-image{
	opacity:0
}

.auth-avatar-placeholder{
	width: 120px;
	height: 120px;
	border-radius: 50%;
	background: #90B7E0;
	color: #fff;
	font-size: 60px;
	text-align: center;
	line-height: 120px;
}

.auth-avatar {
	width: 110%;
	border-radius: 50%;
	background: #90B7E0;
	color: #fff;
	font-size: 60px;
	text-align: center;
	line-height: 120px;
}
.avatar-image {
	display: block;
	max-width: 110%;
}
.preview {
	overflow: hidden;
	width: 160px;
	height: 160px;
	margin: 10px;
	border: 1px solid red;
}
.modal-lg{
	max-width: 1000px !important;
}

.avatarOffset .dz-preview {
	display: none;
}

.avatarOffset .dz-button {
	margin-top: 0px;
}

a.auth-back {
	margin-left: -65px;
	margin-right: 30px;
}
