a:focus, button:focus {
	outline: none;
}

// Default Button

.btn_default {
	display: inline-block;

	background: theme-color(secondary);
	border-radius: 8px;
	padding: 14px 48px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	transition: 0.3s;
	color: white;

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		// padding-top: 12px;
		// padding-bottom: 12px;
		width: 100%;
	}
	@media (max-width:667px) {
		border-radius: 4px;
	}

	.icon {
		padding-right: 17px;
		display: inline-block;
	}

	&:hover {
		background: theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.btn_default_smaller {
	display: inline-block;

	background: theme-color(secondary);
	border-radius: 8px;
	padding: 12px 40px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	transition: 0.3s;
	color: white;
	@media (max-width:667px) {
		border-radius: 4px;
	}

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	.icon {
		padding-right: 17px;
		display: inline-block;
	}

	&:hover {
		background: theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.btn_default_small {
	display: inline-block;
	background: theme-color(secondary);
	border-radius: 4px;
	padding: 10px 20px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	transition: 0.3s;
	color: white;
	@media (max-width:667px) {
		border-radius: 4px;
	}

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	.icon {
		padding-right: 17px;
		display: inline-block;
	}

	&:hover {
		background: theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.btn_submit {
	display: inline-block;

	background: theme-color(secondary);
	border-radius: 4px;
	padding: 0px 24px;
	height: 48px;
	line-height: 48px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	transition: 0.3s;
	color: white;
	border: 2px solid theme-color(secondary);

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	&:hover {
		background: theme-color(secondary-hover);
		border: 2px solid theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.btn_submit.disabled {
	background: theme-color(secondary);
	opacity: 0.4;
	transition: 0.3s;
	color: white;
	pointer-events: none;
	cursor: not-allowed;

	&:hover {
		background: theme-color(secondary);
		color: white;
		cursor: not-allowed;
		pointer-events: none;
	}
}

.btn_save {
	display: inline-block;

	background: theme-color(secondary);
	border-radius: 4px;
	padding: 12px 24px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	transition: 0.3s;
	color: white;
	border: none;

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 12px;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	&:hover {
		background: theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.btn_submit_stroke {

	display: inline-block;
	background: transparent;
	border: 2px solid theme-color(secondary);
	border-radius: 4px;
	padding: 0px 24px;
	height: 48px;
	line-height: 48px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	transition: 0.3s;
	color: theme-color(secondary);

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
		text-align: center;
	}

	&:hover {
		background: theme-color(secondary-hover);
		border: 2px solid theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.btn_submit_stroke.disabled {

	display: inline-block;

	background: transparent;
	border: 2px solid #89A7BC;
	border-radius: 4px;
	padding: 0px 36px;
	height: 48px;
	line-height: 48px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	transition: 0.3s;
	color: #89A7BC;
	pointer-events: none;

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
		text-align: center;
	}

	&:hover {
		cursor: pointer;
	}
}

.btn_default.disabled {
	background: theme-color(secondary);
	opacity: 0.4;
	transition: 0.3s;
	color: white;

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	&:hover {
		background: theme-color(secondary);
		color: white;
		cursor: not-allowed;
	}
}


// Default Button with Stroke

.btn_default--stroke {

	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(secondary);
	border-radius: 8px;
	padding: 14px 48px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	transition: 0.3s;
	color: theme-color(secondary);

	@media (max-width:667px) {
		border-radius: 4px;
	}

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}

	&:hover {
		background: theme-color(secondary-hover);
		border: 2px solid theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;


	}
}

.btn_default--stroke.disabled {
	background: white;
	opacity: 0.4;
	color: theme-color(secondary);
	border: 2px solid theme-color(secondary);

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	&:hover {
		background: white;
		color: theme-color(secondary);
		border: 2px solid theme-color(secondary);
		cursor: not-allowed;
	}
}

.btn_default--stroke_smaller {

	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(secondary);
	border-radius: 4px;
	padding: 12px 24px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	transition: 0.3s;
	color: theme-color(secondary);

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	&:hover {
		background: theme-color(secondary-hover);
		border: 2px solid theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;


	}
}

.btn_default--stroke_smaller.disabled {
	background: white;
	opacity: 0.4;
	color: theme-color(secondary);
	border: 2px solid theme-color(secondary);

	&:hover {
		background: white;
		color: theme-color(secondary);
		border: 2px solid theme-color(secondary);
		cursor: not-allowed;
	}
}

.btn_default--stroke_small {

	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(secondary);
	border-radius: 8px;
	padding: 14px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	transition: 0.3s;
	color: theme-color(secondary);
	@media (max-width:667px) {
		border-radius: 4px;
	}

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	&:hover {
		background: theme-color(secondary-hover);
		border: 2px solid theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;


	}
}

.team_modal_btn {

	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(secondary);
	border-radius: 8px;
	padding: 10px 32px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	transition: 0.3s;
	color: theme-color(secondary);
	@media (max-width:667px) {
		border-radius: 4px;
	}

	@media (max-width: 667px) {
		line-height: unset;
		width: 100%;
	}

	&:hover {
		background: theme-color(secondary-hover);
		border: 2px solid theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;


	}
}

// Accent Button with Stroke

.btn_accent {
	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(accent);
	border-radius: 8px;
	padding: 14px 48px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	transition: 0.3s;
	color: theme-color(primary);
	@media (max-width:667px) {
		border-radius: 4px;
	}

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	&:hover {
		background: theme-color(accent);
		border: 2px solid theme-color(accent);
		text-decoration: none;
		color: theme-color(primary);
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.btn_accent_smaller {
	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(accent);
	border-radius: 8px;
	padding: 16px 26px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	transition: 0.3s;
	color: theme-color(primary);
	@media (max-width:667px) {
		border-radius: 4px;
	}

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	&:hover {
		background: theme-color(accent);
		border: 2px solid theme-color(accent);
		text-decoration: none;
		color: theme-color(primary);
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.btn_accent_sm {
	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(accent);
	border-radius: 4px;
	padding: 14px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	transition: 0.3s;
	color: theme-color(primary);

	&:hover {
		background: theme-color(accent);
		border: 2px solid theme-color(accent);
		text-decoration: none;
		color: theme-color(primary);
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.btn_accent_alt {
	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(accent);
	border-radius: 8px;
	padding: 14px 48px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	transition: 0.3s;
	color: white;
	@media (max-width:667px) {
		border-radius: 4px;
	}

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	&:hover {
		background: theme-color(accent);
		border: 2px solid theme-color(accent);
		text-decoration: none;
		color: theme-color(primary);
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.btn_accent_alt_md {
	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(accent);
	border-radius: 8px;
	padding: 14px 48px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	transition: 0.3s;
	color: white;
	@media (max-width:667px) {
		border-radius: 4px;
	}

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	&:hover {
		background: theme-color(accent);
		border: 2px solid theme-color(accent);
		text-decoration: none;
		color: theme-color(primary);
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.btn_accent.disabled {
	background: white;
	opacity: 0.4;
	color: theme-color(primary);
	border: 2px solid theme-color(accent);

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	&:hover {
		background: white;
		color: theme-color(primary);
		border: 2px solid theme-color(accent);
		cursor: not-allowed;
	}
}

.btn_extend {
	padding: 16px 98px;
}

.btn_text {
	border: none;
	background: none;
	font-size: 16px;
	line-height: 24px;
	color: #004C7F;
	font-weight: bold;

	img {
		margin-right: 9px;
	}
}


.btn_linkedin {
	display: inline-block;

	background: #2F72AC;
	border-radius: 4px;
	//	padding: 12px 40px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	height: 48px;
	line-height: 48px;
	transition: 0.3s;
	color: white;

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	.icon {
		padding-right: 8px;
		display: inline-block;
	}

	&:hover {
		background: theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.text-link {

	transition: 0.3s;
	&:hover {
		text-decoration: none;
	}
}

.button_reset {
	border: none;
	background: transparent;
}


.p-link {

	color: #004C7F;
	font-weight: bold;

	transition: 0.3s;
	&:hover {
		color: theme-color(secondary);
		text-decoration: none;
	}
}


.contact_text_link {
	color: #004C7F;
	font-size: 20px;

	transition: 0.3s;
	&:hover {
		color: theme-color(secondary);
		text-decoration: none;
	}
}

.modal-dialog {
	button {
		height: 40px!important;
		line-height: 0;
	}
}

.btn_default_xsmall {
	display: inline-block;
	background: theme-color(secondary);
	border-radius: 4px;
	padding: 10px 16px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	transition: 0.3s;
	color: white;
	@media (max-width:667px) {
		border-radius: 4px;
	}

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	.icon {
		padding-right: 11px;
		display: inline-block;
		width: 29px;
	}

	&:hover {
		background: theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.btn_accent_fp_sm {
	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(accent);
	border-radius: 4px;
	padding: 6px 12px;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	transition: 0.3s;
	color: theme-color(primary);

	&:hover {
		background: theme-color(accent);
		border: 2px solid theme-color(accent);
		text-decoration: none;
		color: theme-color(primary);
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}


// Rock Status

.btn-rock-red {
    background: red !important;
    border: 1px solid red;
}

.btn-rock-amber {
    background: rgb(232, 147, 29) !important;
    border: 1px solid rgb(232, 147, 29);
}

.btn-rock-green {
    background: green !important;
    border: 1px solid green;
}

.btn-rock-lg > .btn {
    padding: 2rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
}

.btn-rock-lg i {
    line-height: 5px !important;
    font-size: 30px;
}

.btn-padd-adjust {
	padding-top: 10px !important;
    padding-bottom: 10px !important;
}