.intl-tel-input {
	display: block !important;
	&:focus {
		outline: none;
	}
}
.intl-tel-input .selected-flag {
	z-index: 4;
	&:focus {
		outline: none;
	}
}
.intl-tel-input .country-list {
	z-index: 5;
	&:focus {
		outline: none;
	}
}
html body .selectize-control.single .selectize-input:after {
	display: none;
}

html body .selectize-control.single .selectize-input > span {
	cursor: pointer;
	margin: 0 10px 0px 0;
	padding-top: 2px;
	padding-right: 25px !important;
	padding-bottom: 2px;
	padding-left: 12px;
	background: #004c7f !important;
	color: #fff !important;
	border: 0 solid #d0d0d0 !important;
	border-radius: 4px;
	margin-bottom: 10px;
}

html body .selectize-control.plugin-remove_button .remove-single {
	position: absolute;
	font-size: 20px;
	color: white;
	font-weight: 600;
	right: 6px;
	top: 1px;
	text-decoration: none;
}

.iti__selected-flag:focus {
	outline: none;
}
html body #table-body-content{
	font-size: 10px;
}

html body #header-name{
	font-size: 10px;
}
.hide-table-initial{
	display: none;
	position: absolute;
	background: #eeeeee;
	color: #000000;
	border: 1px solid #1a1a1a;
	width: 50%;
	z-index : 99;
	right : 1em;
}
.trigger-cursor{
	cursor: pointer;
}

.input-group .intl-tel-input .form-control {
	border-top-left-radius: 4px;
	border-top-right-radius: 0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 0;
	&:focus {
		outline: none;
	}
}

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
	padding-left: 23px;
	padding-right: 23px;
	margin-right: 23px;
	width: 148px;
	&:focus {
		outline: none;
	}
}

.selected-flag {
	width: 105px !important;
}

.intl-tel-input .selected-flag {
	z-index: 4;
}
.intl-tel-input .country-list {
	z-index: 5;
}
.input-group .intl-tel-input .form-control {
	border-top-left-radius: 4px;
	border-top-right-radius: 0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 0;
}

.form_background {
	background: #f8f8f9;
	border-radius: 8px;
	padding: 30px;
	@media (max-width: 667px) {
		border-radius: 4px;
	}
}

.industry_item {
	background: #f8f8f9;
	border-radius: 8px;
	padding: 10px 0px 0px 20px;
	margin-bottom: 1.5rem;
	@media (max-width: 667px) {
		border-radius: 4px;
	}
}

.percent-width {
	max-width: 75px;
}

.toggle_wrapper {
	display: flex;
}

.intl-tel-input.separate-dial-code .selected-flag {
	background-color: white !important;
	border-right: 1px solid #d6d5d5 !important;
}

.form-group .form-row {
	text-align: left;
}

.form-control {
	min-height: 64px !important;
	font-family: Helvetica !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 16px !important;
	line-height: 20px;
	color: #333333 !important;
	padding: 16px !important;
	border: 1px solid #d6d5d5 !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	border-color: #eb7c63 !important;
	padding-right: calc(1.5em + 0.75rem);
	background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 0C5.03678 0 0 5.03678 0 11C0 16.9632 5.03678 22 11 22C16.9632 22 22 16.9632 22 11C22 5.03678 16.9632 0 11 0ZM12.2222 15.8889C12.2222 16.5639 11.675 17.1111 11 17.1111C10.325 17.1111 9.77778 16.5639 9.77778 15.8889C9.77778 15.2139 10.325 14.6667 11 14.6667C11.675 14.6667 12.2222 15.2139 12.2222 15.8889ZM12.2222 11C12.2222 11.675 11.675 12.2222 11 12.2222C10.325 12.2222 9.77778 11.675 9.77778 11V6.11111C9.77778 5.4361 10.325 4.88889 11 4.88889C11.675 4.88889 12.2222 5.4361 12.2222 6.11111V11Z' fill='%23EB7C63'/%3E%3C/svg%3E%0A");
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	display: block;
}

.form-control.is-valid,
.was-validated .form-control:valid {
	background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 11C22 13.1756 21.3549 15.3023 20.1462 17.1113C18.9375 18.9202 17.2195 20.3301 15.2095 21.1627C13.1995 21.9952 10.9878 22.2131 8.85401 21.7886C6.72022 21.3642 4.76021 20.3166 3.22183 18.7782C1.68345 17.2398 0.635804 15.2798 0.211367 13.146C-0.213071 11.0122 0.00476617 8.80047 0.83733 6.79048C1.66989 4.78049 3.07979 3.06253 4.88873 1.85383C6.69767 0.645139 8.82441 0 11 0C13.9174 0 16.7153 1.15893 18.7782 3.22183C20.8411 5.28473 22 8.08262 22 11ZM9.72701 16.824L17.888 8.663C18.0199 8.53002 18.0939 8.3503 18.0939 8.163C18.0939 7.9757 18.0199 7.79598 17.888 7.663L16.888 6.663C16.755 6.5311 16.5753 6.45708 16.388 6.45708C16.2007 6.45708 16.021 6.5311 15.888 6.663L9.22501 13.311L6.11801 10.204C5.98502 10.0721 5.80531 9.99808 5.61801 9.99808C5.4307 9.99808 5.25099 10.0721 5.11801 10.204L4.11801 11.204C3.9861 11.337 3.91209 11.5167 3.91209 11.704C3.91209 11.8913 3.9861 12.071 4.11801 12.204L8.731 16.817C8.86289 16.9486 9.04124 17.0231 9.22757 17.0244C9.4139 17.0258 9.59328 16.9538 9.72701 16.824V16.824Z' fill='%235CE5B0'/%3E%3C/svg%3E%0A");
}

.invalid-feedback {
	display: block;
}

::-webkit-input-placeholder {
	/* Edge */
	color: #333333;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #333333;
}

::placeholder {
	color: #333333;
}

.form-check-input {
	margin-top: 0;
}

.field_highlight {
	transition: 0.3s;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-left: -15px;
	padding-left: 15px;
	padding-right: 15px;
	margin-right: -15px;

	@media (max-width: 667px) {
		padding-top: 0;
		padding-top: 10px;
		padding-bottom: 0px;
	}

	&:hover {
		background: #f8f8f9;
		border-radius: 8px;
		@media (max-width: 667px) {
			border-radius: 4px;
		}

		.form-tool {
			opacity: 1;
		}
	}
}

.form-tool {
	background: #ffffff;
	border-radius: 4px;
	padding: 20px;
	margin-left: 45px;
	position: relative;
	opacity: 0;
	transition: 0.3s;

	p {
		color: #555555;
		font-size: 14px;
		line-height: 20px;
		padding-left: 20px;
	}

	&:before {
		content: "";
		left: -10px;
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 10px 5px 0;
		border-color: transparent #fff transparent transparent;
	}
}

.form-tool--grey {
	background: #f8f8f9;
	border-radius: 4px;
	padding: 20px;
	padding-left: 0;
	margin-left: 45px;
	position: relative;
	// max-width: 390px;

	p {
		color: #555555;
		font-size: 14px;
		line-height: 20px;
		padding-left: 20px;
	}

	&:before {
		content: "";
		left: -10px;
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 10px 5px 0;
		border-color: transparent #f8f8f9 transparent transparent;
	}

	li {
		color: #555555;
		font-size: 14px;
		line-height: 20px;
		padding-left: 0px;
	}
}

.form-tool--error {
	background: rgba(235, 124, 99, 0.1);
	border: 1px solid #eb7c63;
	border-radius: 4px;
	padding: 20px;
	margin-left: 45px;
	position: relative;
	// max-width: 390px;

	p {
		color: #131936;
		font-size: 14px;
		line-height: 20px;
		padding-left: 20px;
	}

	// &:before {
	// 	content: '';
	// 	left: -10px;
	// 	position: absolute;
	// 	width: 0;
	// 	height: 0;
	// 	border-style: solid;
	// 	border-width: 5px 10px 5px 0;
	// 	border-color: transparent rgba(235, 124, 99, 0.1) transparent transparent;

	// }
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #f8f8f9;
	opacity: 1;
}

select.select_lg {
	height: 84px;
	-moz-white-space: pre-wrap;
	-o-white-space: pre-wrap;
	white-space: pre-wrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.date_select {
	max-width: 220px;
	position: relative;

	@media (max-width: 667px) {
		max-width: 100%;
	}
}

.date_select_full {
	position: relative;
}

.date_icon {
	position: absolute;
	right: 20px;
	bottom: 20px;
}

.date-icon-start{
	position: absolute;
	right: 32px;
	bottom: 20px;
}

.selectize-input {
	min-height: 64px !important;
	line-height: 28px;
	color: #333333 !important;
	padding: 16px !important;
	border: 1px solid #d6d5d5 !important;
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
	color: #303030;
	font-size: 14px;
}

.selectize-control.multi .selectize-input > div {
	cursor: pointer;
	margin: 0 10px 0px 0;
	padding-top: 2px;
	padding-right: 25px !important;
	padding-bottom: 2px;
	padding-left: 12px;
	background: #004c7f !important;
	color: #fff !important;
	border: 0 solid #d0d0d0 !important;
	border-radius: 4px;
	margin-bottom: 10px;
}

.selectize-control.multi .selectize-input.has-items .active {
	background: #131936 !important;
	color: #fff !important;
	border: 0 solid #131936 !important;

	.remove {
		color: #16e7cf !important;
	}
}

.intl-input {
	padding-left: 120px !important;
}

.close_employment,
.delete_employment,
.delete_industry {
	border: none;
	background: transparent;

	&:focus {
		outline: none;
	}
}

.delete_industry {
	margin-top: 20px;
	display: block;
	margin-left: 7px;
}

.delete_experience_item {
	border: none;
	background: transparent;

	&:focus {
		outline: none;
	}
}

.toggle_wrapper {
	input[type="checkbox"] {
		height: 0;
		width: 0;
		visibility: hidden;
	}

	label {
		cursor: pointer;
		text-indent: -9999px;
		width: 40px;
		height: 23px;
		background: #d6d5d5;
		display: block;
		border-radius: 100px;
		position: relative;
		margin-bottom: 0;
		margin-top: 5px;
	}

	label:after {
		content: "";
		position: absolute;
		top: 3px;
		left: 3px;
		width: 17px;
		height: 17px;
		background: #fff;
		border-radius: 15px;
		transition: 0.3s;
	}

	input:checked + label {
		background: theme-color(secondary);
	}

	input:checked + label:after {
		left: calc(100% - 3px);
		transform: translateX(-100%);
	}

	label:active:after {
		width: 40px;
	}
}

.form-control.plain_text {
	border: none !important;
	background: transparent;
	padding-left: 0 !important;
}

.selectize-control.plugin-remove_button [data-value] .remove {
	z-index: 1;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 25px;
	text-align: center;
	font-weight: bold;
	font-size: 20px;
	line-height: 31px;
	color: inherit;
	text-decoration: none;
	vertical-align: middle;
	display: inline-block;
	padding: 0px 0px 0px 0px;
	border-left: none;
	-webkit-border-radius: 0 2px 2px 0;
	-moz-border-radius: 0 2px 2px 0;
	border-radius: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

// Selectize

.selectize-dropdown {
	padding: 0px;
}

.selectize-dropdown .active {
	background-color: #f2f2f2 !important;
}

.select-holder {
	margin-bottom: 0px;
	font-size: 16px;
	padding: 20px 10px 20px 10px;
}

.selectize-input.items.required.has-options.full.has-items {
	padding-right: 30px !important;
}

// Custom checkbox
.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: #3279c6;
	background-color: #3279c6;
}

.custom-control-label {
	padding-top: 2px;
}

// Typeahead

.twitter-typeahead {
	width: 100%;
}

.tt-menu {
	width: 100%;
}

.tt-suggestion {
	background: white;
	padding: 10px;
	font-size: 12px;
	border-bottom: 1px dashed #efefef;
	cursor: pointer;
}

// counters

.experience-count {
	font-size: 14px;
	line-height: 20px;
	float: right;
}

#qualifyingExamList .table th,
.table td {
	padding: 0.75rem;
	padding-top: 0.75rem;
	padding-right: 0rem;
	padding-bottom: 0.75rem;
	padding-left: 0rem;
}

// image uploads

.dz-button {
	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(secondary);
	border-radius: 8px;
	padding: 14px;
	margin-top: 40px;
	@media (max-width: 667px) {
		border-radius: 4px;
	}

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	transition: 0.3s;
	color: theme-color(secondary);

	@media (max-width: 667px) {
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	&:hover {
		background: theme-color(secondary-hover);
		border: 2px solid theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}
}

.dz-default.dz-message {
	width: 140px;
	display: inline;
}

.dz-image {
	height: 120px;
	width: 120px;
	background: #90b7e0;
	border-radius: 200px;
}

.dz-image img {
	width: 120px;
	height: 120px;
	border-radius: 200px;
	margin-bottom: 20px;
}

.dropzone .dz-preview {
	margin-right: 15px;
	margin-left: 0px;
}

.dz-preview {
	float: left;
}

.dz-details {
	display: none;
}

.dz-progress {
	display: none !important;
}

.dz-error-message {
	font-size: 12px;
}

.form-control[readonly] {
	background-color: white;
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
	font-family: Helvetica !important;
	color: #333333 !important;
	font-size: 16px;
	font-weight: normal;

	::-webkit-input-placeholder {
		/* Edge */
		color: #333333;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #333333;
	}

	::placeholder {
		color: #333333;
	}
}

.select-caption {
	font-family: Helvetica !important;
	width: 100%;
	display: inline-block;
	font-size: 12px;
	color: #333333 !important;
}

.select-hidden {
	display: none;
}

html body .form-education-error{
	background: rgba(235, 124, 99, 0.1);
	border: 1px solid #eb7c63;
	border-radius: 4px;
	padding: 20px;
	margin-left: 0;
	position: relative;
	width: 41%;
}
