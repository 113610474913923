.eopCards {
	padding: 24px 32px;
}

.collapse_indicator {
	transition: 0.3s;
	transform: rotate(0deg);
}

.collapsed .collapse_indicator {
	transform: rotate(180deg);
}

.feedback_wrapper {
	.dropdown-menu {
		border: none !important;
		margin-top: 16px;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.07),
			0px 3px 14px rgba(0, 0, 0, 0.06), 0px 5px 5px rgba(0, 0, 0, 0.1);
	}

	.dropdown img {
		transition: 0.3s;
		transform: rotate(0deg);
	}

	.dropdown.show {
		img {
			transform: rotate(180deg);
		}
	}
}

.feedback_card {
	padding: 24px 40px;

	.avatar {
		width: 48px;
		height: 48px;
		line-height: 48px;
		margin-right: 12px;
		background: #90b7e0;
		color: #fff;
		text-align: center;
	}

	.icon {
		width: 17px;
		height: auto;
	}
}

.impact_card {
	border-radius: 4px;
	border: 1px solid #d6d5d5;
	overflow: hidden;
	position: relative;
	transition: 0.3s;

	&:hover {
		cursor: pointer;
		border: 1px solid #2b6aad;
	}

	.content {
		padding: 20px 24px;
	}

	.img_wrapper {
		overflow: hidden;
		position: relative;
		transition: 0.3s;
	}

	.selected_text {
		display: none;
		transition: 0.3s;
	}

	.selected_overlay {
		display: none;
		transition: 0.3s;
	}
	.selected_overlay {
		transition: 0.3s;
	}
}

.impact_card.selected {
	border: 2px solid #2b6aad;

	.selected_text {
		display: block;
		padding: 18px;
	}

	.selected .img_wrapper {
		height: 270px;
	}

	.selected_overlay {
		transition: 0.3s;
		display: block;
		background: rgba(0, 0, 0, 0.4);
		width: 100%;
		height: 100%;
		position: absolute;
		text-align: center;
		padding-top: 50px;

		img.logo {
			position: absolute;
			right: 20px;
			bottom: 20px;
		}

		h2 {
			font-family: Helvetica;
			font-style: normal;
			font-weight: bold;
			font-size: 72px;
			margin-bottom: 0;
		}

		h3 {
			font-family: Helvetica;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			margin-top: 8px;
			text-align: center;
			color: #ffffff;
			line-height: 32px;
		}
	}
}

.impact_datasets {
	.avatar {
		width: 100px;
		height: 100px;
	}
}
.rotate {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

.dataset {
	display: none;
}

// .active {
// 	display: block;
// }

.diversity_wrapper {
	background: #f2f7fc;
	padding-top: 40px;
	padding-bottom: 78px;
}

.notification_box {
	padding: 12px 16px;
	background: #f2f2f2;
	border-radius: 4px;
}

.btn_loadmore_reset {
	margin: auto;
	border: none;
	background: transparent;
	display: block;
	font-size: 20px;
}

// DONUT

#svg,
#EthnicChart {
	width: 100%;
	height: 200px;
}

h4.gender_value.font-weight-bold.text-primary {
	position: absolute;
	left: 95px;
	top: 145px;
	font-size: 25px;
}

h4.ethnic_value.font-weight-bold.text-primary {
	position: absolute;
	left: 95px;
	top: 145px;
	font-size: 25px;
}

text {
	font-family: Helvetica;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
	color: #333;
}

.impact_item {
	border-radius: 8px;
	padding: 20px;
	text-align: center;

	h2 {
		font-size: 8rem;
		line-height: 8rem;
	}
}
