.collapse_indicator {
	transform: rotate(180deg);
	transition: 0.3s;
}

.collapse_indicator.down {
	transform: rotate(0deg);
	transition: 0.3s;
}

.under_review_block {
	background: rgba(22, 231, 207, 0.05);
	border: 1px solid #16E7CF;
	border-radius: 8px;
	padding: 20px 34px;
	@media (max-width:667px) {
		border-radius: 4px;
	}
}

.leaderboard_header {

	.position {
		width: 24px;
		height: 24px;
		background: #D5E3E7;
		border: 2px solid;
		font-size: 14px;
		margin: auto;
		display: block;
		border-radius: 13px;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
	}

	.badge {
		padding: 4px 12px;
		border-radius: 20px;
	}

	.badge_first {
		background: #FBE25A;
		color: #453A00;
	}

	.badge_second {
		background: #D5E3E7;
		color: #003645;
		border-color: #A9C6CF;
	}

	.badge_third {
		background: #F5C193;
		color: #452000;
		border-color: #CC9666;
	}

	.leader_card {
		background: #FFFFFF;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
		border-radius: 4px;
	}

	.highlight {
		border: 3px solid #16E7CF;
	}

	img {
		margin: auto;
		margin-bottom: 16px;
	}
}

.leaderboard {
	background: #FFFFFF;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
	border-radius: 4px;

	.item {
		text-align: center;
		padding-top: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #DEDEDE;
		width: 100%;

		.position {
			width: 24px;
			height: 24px;
			background: #F2F2F2;
			font-size: 14px;
			color: #131936;
			text-align: center;
			border-radius: 13px;
			padding-top: 2px;
			font-weight: 600;
			margin-right: 20px;
		}

		.badge {
			background: #F2F2F2;
			padding: 4px 12px;
			border-radius: 20px;
			color: #003645;
			font-size: 12px;
			font-weight: 400;
		}
	}

	.item:last-child {
		border-bottom: none;
	}
}

.pagination {

	li.page-item {
		margin-left: 4px;
		margin-right: 4px;
	}

	.page-link {
		position: relative;
		display: block;
		padding: 0.5rem 0.90rem;
		margin-left: -1px;
		font-size: 14px;
		line-height: 20px;
		color: #555555;
		background-color: #fff;
		border: 1px solid #DEDEDE;
		border-radius: 4px;

	}

	.page-item.active .page-link {
		z-index: 3;
		color: #fff;
		background-color: #2B6AAD;
		border-color: #2B6AAD;
		border-radius: 4px;
	}


}
