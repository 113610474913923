.update_card {
	padding: 20px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08),
		0px 2px 14px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
}

.requested_card_wrapper {
	// border: 2px solid #F8F8F9;
	margin-bottom: 20px;
	position: relative;
	&:last-of-type {
		margin-bottom: 0;
	}
}

.container_xl.pt-lg.padding_resize {
	padding-top: 60px !important;
}

.request_card {
	display: block;
	width: 100%;
	padding: 16px 20px;

	&:hover {
		text-decoration: none;
	}
}

.request_removepadding {
	padding: 16px 0px !important;
}

.edit_request_link {
	position: absolute;
	right: 0;
	top: 20px;
	font-size: 14px;
	font-weight: 700;
	color: theme-color(secondary);
	transition: 0.3s;

	&:hover {
		color: theme-color(secondary-hover);
		text-decoration: none;
	}
}

.request_item {
	background: #f8f8f9;
	padding: 0 24px !important;
	border-radius: 4px;
	max-height: 80px;
	height: 80px;

	.col-12 span {
		position: relative;
	}
}

.candidate_rejected {
	display: none;
}

.empty {
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;

	.card-header {
		background: #f2f2f2 !important;
		border-radius: 4px;
		padding: 24px;
	}
}
.request_collapse {
	.card-header,
	.card-body {
		padding-left: 0px;
		padding-right: 0px;
	}

	.card-body {
		padding-top: 0;
	}

	.card-header {
		border-bottom: none;
	}

	.text_link {
		font-family: Helvetica;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: #004c7f;
		transition: 0.3s;
		&:hover {
			text-decoration: none;
			color: theme-color(primary);
		}
	}
}

.request_collapse.show,
.request_collapse.collapsing {
	background: white;

	.card-header,
	.card-body {
		background: white;
		padding-bottom: 0;
	}
}

.joining_soon {
	.card-header,
	.card-body-team {
		padding-left: 0px;
		padding-right: 0px;
	}

	.card-body-team {
		padding-top: 0;
	}

	.card-header {
		border-bottom: none;
	}

	.text_link {
		font-family: Helvetica;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: #004c7f;
		transition: 0.3s;
		&:hover {
			text-decoration: none;
			color: theme-color(primary);
		}
	}
}

.manager_card {
	background: white;
	padding: 20px 24px;
	box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
	border-radius: 8px;

	.item {
		img {
			display: inline-block;
			width: 17px;
			margin-right: 10px;
		}
	}
}

.project_card {
	background: #f8f8f9;
	border-radius: 4px;
	padding-top: 48px;
	padding-bottom: 48px;

	@media (max-width: 992px) {
		padding: 20px;
	}

	.icon {
		background: #dedede;
		text-align: center;
		width: 124px;
		height: 124px;
		margin: auto;
		border-radius: 80px;
		display: block;

		font-family: Helvetica;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: #131936;

		padding-top: 30px;
	}
}

.manager_item {
	background: #cceeff;
}

.updates_card {
	position: relative;
	overflow: hidden;
	padding: 10px 16px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08),
		0px 2px 14px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	min-height: 190px;
	background: white;

	.background_image {
		position: absolute;
		top: 20px;
		opacity: 0.1;
	}

	.card_tag {
		font-size: 12px;
		line-height: 16px;
		color: #05a4ca;
		margin-bottom: 8px;
	}

	.buttons {
		position: absolute;
		bottom: 20px;
		right: 20px;
		text-align: right;
		z-index: 1;

		@media (max-width: 667px) {
			position: relative;
			text-align: center;
			bottom: unset;
			right: unset;
		}

		.text-link {
			color: #004c7f;
			font-weight: bold;
			font-size: 14px;
			line-height: 20px;
		}
	}

	.btn_accent_sm,
	.btn_default--stroke_small,
	.btn_default_small {
		border-radius: 4px !important;
		padding: 10px 16px !important;

		@media (max-width: 667px) {
			width: 100%;
		}
	}
}

.modal_card {
	background: #f8f8f9;
	border-radius: 4px;
	padding: 20px;

	.badge {
		background: #e1e4ec;
		border-radius: 4px;
		font-size: 14px;
		color: #333;
		padding: 4px 8px;
	}
}

.modal_accept {
	border: 2px solid #16e7cf;
	border-radius: 8px;
	padding: 18px;
	display: inline-block;
	width: 100%;
	color: 131936;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	background: transparent;
	transition: 0.3s;

	&:hover {
		background: #16e7cf;
		text-decoration: none;
	}
}

.modal_reject {
	border: 2px solid #eb7c63;
	border-radius: 8px;
	padding: 18px;
	display: inline-block;
	width: 100%;
	color: 131936;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	background: transparent;
	transition: 0.3s;
	&:hover {
		background: #eb7c63;
		text-decoration: none;
	}
}

.modal_checklist {
	border: 2px solid #dedede;
	border-radius: 8px;
	padding: 18px;
	display: inline-block;
	width: 100%;
	color: #555555;
	text-align: left;
	background: transparent;
	transition: 0.3s;

	&:hover {
		// border: 2px solid #3279C6;
		text-decoration: none;
	}

	.custom-control-label {
		font-size: 16px;
	}
}

.checklist_completed {
	border: 2px solid #3279c6;
}

.error_item {
	display: block;
	width: 100%;
	background: rgba(235, 124, 99, 0.1);
	border: 1px solid #eb7c63;
	border-radius: 4px;
	padding: 12px;
}

#referralPolicyPublic {
	.modal-body {
		padding: 0 !important;

		.row {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	h4 {
		line-height: 0;
		margin-bottom: 0;
	}
}

.status_btn {
	background: transparent;
	display: block;
	border-radius: 4px;
	width: 36px;
	height: 36px;
	text-align: center;
	padding-top: 5px;
	transition: 0.3s;
	position: relative;

	&:hover {
		background: #e1e4ec;
	}

	.notification {
		display: block;
		width: 8px;
		height: 8px;
		border-radius: 8px;
		background: #16e7cf;
		position: absolute;
		right: 5px;
	}
}

.user_btn {
	background: transparent;
	display: block;
	border-radius: 4px;
	text-align: center;
	padding-top: -1px;
	transition: 0.3s;
	position: relative;
}

.project_btn {
	background: transparent;
	display: block;
	border-radius: 4px;
	width: auto;
	text-align: center;
	padding: 5px;
	transition: 0.3s;
	position: relative;
	font-size: 14px;
	line-height: 36px;

	img {
		padding-right: 5px;
	}

	&:hover {
		background: #e1e4ec;
		text-decoration: none;
	}

	.notification {
		display: block;
		width: 8px;
		height: 8px;
		border-radius: 8px;
		background: #16e7cf;
		position: absolute;
		right: 5px;
	}
}

.review_btn {
	display: inline-block;

	background: theme-color(secondary);
	border-radius: 4px;
	padding: 5px 16px;
	height: 34px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	transition: 0.3s;
	color: white;
	border: 2px solid theme-color(secondary);

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	&:hover {
		background: transparent;
		border: 2px solid theme-color(secondary-hover);
		text-decoration: none;
		color: theme-color(secondary-hover);
		cursor: pointer;
	}
}

.review_btn_stroke {
	display: inline-block;

	background: transparent;
	border-radius: 4px;
	padding: 5px 16px;
	height: 34px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	transition: 0.3s;
	color: theme-color(secondary);
	border: 2px solid theme-color(secondary);

	@media (max-width: 667px) {
		line-height: unset;
		font-size: 16px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 100%;
	}

	&:hover {
		background: theme-color(secondary);
		border: 2px solid theme-color(secondary-hover);
		text-decoration: none;
		color: white;
		cursor: pointer;
	}
}

.request_collapse .card-header {
	border-bottom: none;
	background: #f8f8f9 !important;
	border-radius: 4px;
	padding: 30px;
}

.active_requests {
	color: #fff;
	background-color: #131936;
	border-radius: 16px;
	font-size: 20px;
	height: 32px;
	font-weight: 700 !important;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
	display: inline-block;
	line-height: 0;
	padding-top: 17px;
}

#oppurtunity_accept {
	.info_card {
		background: #f2f2f2;
		border-radius: 4px;
		padding: 12px 16px;

		p {
			color: #333;
		}
	}
}

.form_block {
	background: #f8f8f9;
	border-radius: 4px;
	padding: 20px 24px;
}

.fullwidth_dateselect {
	width: 100%;
	max-width: 100%;

	.form-control {
		width: 100%;
	}
}

.attendees_group {
	.item {
		margin-bottom: 16px !important;
	}
	.radio__label img {
		max-width: 24px;
	}

	.text-link {
		font-size: 14px;
		margin-top: 32px;
	}
}

.user_badge {
	color: white;
	background: #131936;
	border-radius: 4px;
	padding: 4px 8px;
	font-size: 14px;
}

.user_badge_muted {
	color: #131936;
	background: #e1e4ec;
	border-radius: 4px;
	padding: 4px 8px;
	font-size: 14px;
}

.elipsis {
	height: 100%;

	button {
		border: none;
		background: transparent;
		height: 100%;
		padding-left: 15px;
		padding-right: 15px;

		&:hover {
			background: rgba(222, 222, 222, 0.4);
		}
	}
}

.request_item.p-0 {
	padding: 0 !important;
}

.info_block {
	background: #f2f2f2;
	border-radius: 4px;
	padding: 20px 16px;
}

.endgagement_card {
	background: #f8f8f9;
	border-radius: 4px;
	padding-top: 32px;
	padding-bottom: 32px;
}

.avatar_radio {
	.radio_avatar {
		width: 24px;
		height: 24px;
	}
}

// #engagementback {
// 	padding-top: 14px;
// 	margin-right: 20px;
// }

.report_item {
	background: #f8f8f9;
	border-radius: 4px;
	padding: 20px 14px;
	margin-left: 0;
	margin-right: 0;
}

.energy_block {
	display: block;
	width: 100%;
	background: #a8ddb5;
	height: 30px;
	margin-top: 10px;
}

#collapseEngadgement {
	.rating_item {
		.rating_stars {
			margin-top: -2px;
		}
	}
}

.bars {
	height: 20px;
	.bar_blue {
		background: #3279c6;
		display: block;
		height: 20px;
	}
	.bar_grey {
		background: #555;
		display: block;
		height: 20px;
	}

	p {
		font-size: 12px;
	}
}

.skill_item {
	.star {
		width: 20px;
		margin-top: -3px;
		margin-left: 8px;
	}
}

.small-modal {
	.modal-dialog {
		max-width: 600px !important;
	}
}

.extend_card {
	background: white;
	border: 1px solid #d6d5d5;
	border-radius: 4px;
	padding: 16px;
	height: 100%;
	margin-bottom: 16px;
}

.extend_card_reason {
	background: white;
	border: 1px solid #d6d5d5;
	border-radius: 4px;
	padding: 16px;
	margin-bottom: 16px;
}

.extend_card_highlight {
	border: 1px solid #3279c6;
}

.avatar40 {
	width: 40px;
	height: 40px;
	margin-right: 12px;
}

.am_card {
	background: #f8f8f9;
	padding: 16px 20px !important;
	border-radius: 4px;
	margin-bottom: 16px;
	&:last-of-type {
		margin-bottom: 0;
	}
}

.legend_industry_item {
	width: 100%;
	font-size: 12px;
	margin-bottom: 0px;

	.index-1 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}

	.index-2 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}

	.index-3 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}

	.index-4 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}

	.index-5 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}

	.index-6 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}

	.index-7 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}

	.index-8 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}

	.index-9 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}

	.index-10 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}

	.index-11 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}

	.index-12 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}
}

.engagements_more {
	font-size: 14px;
	line-height: 20px;
	position: absolute !important;
	right: 0px;
	top: 30px;
}

.card_bubble {
	border-radius: 50%;
	background-color: #efefef;
	text-align: center;
	padding: 4px;
	font-weight: bold;
	line-height: 30px;
}
