.admin-form {
	display: contents;
}

.date_full {
	max-width: none !important;
}


.statusFilterBox select {
	display: block;
	width: 232px;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #797979;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #D6D5D5;
	border-radius: 4px;
	-webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.radio-size-large{
	height:25px;
	width:25px;
	vertical-align: middle;
}

.rejection-reason
{
	border: 2px solid #ff0000;
	position:absolute;
	color: white;
	bottom: 22em;
	left: 14em;
	background-color: rgba(0, 0, 0, 0.69);
	padding: 10px;
	border-radius: 5px;

}

.request-table-scroller{
	// overflow-x:auto;
}

div.note-editable{
	height: 230px;
}
.note-editor .note-editable {
	line-height: 24px;
	font-size: 16px;
}
.summernote-font{
	font-family: Helvetica, serif;
	color: #0b0b0b;
}

.summernote-color{
	color: #000000;
}

html body td.summernote-font p{
	font-size: 14px;
	line-height: 21px;
}
