.employement_card {
	background: white;
	border: 1px solid #D6D5D5;
	border-radius: 8px;
	position: relative;
	padding: 30px;

	@media (max-width:667px) {
		border-radius: 4px;
	}

	.card_header {
		border-bottom: 1px solid #D6D5D5;
		padding-bottom: 20px;
	}

	.card_body {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			display: grid;
			grid-gap: 10px;

			li {
				display: grid;
				grid-template-columns: 0 1fr;
				grid-gap: 1.5em;
				align-items: start;
				font-size: 14px;
				line-height: 24px;
				width: 85%;
			}

			li::before {
				content: "\2022";
				color: #3279C6;
				font-weight: bold;
				display: inline-block;
				width: 15px;
				line-height: 25px;
				font-size: 30px;
				vertical-align: middle;
			}

		}


		.work_badges {
			display: block;
			width: 100%;

			.item {
				background: #E1E4EC;
				border-radius: 4px;
				padding: 2px 8px;
				display: inline-block;
				margin-right: 10px;
				margin-bottom: 10px;
				color: #333333;
				font-size: 14px;
				@media (max-width:667px) {
					border-radius: 4px;
				}
			}
		}
	}

	.delete_box {
		background: white;
		border: 1px solid #D6D5D5;
		border-radius: 8px;
		padding: 15px;
		font-size: 14px;
		text-align: center;
		max-width: 200px;
		position: absolute;
		top: 0;
		right: -220px;
		display: none;
		@media (max-width:667px) {
			border-radius: 4px;
		}

		.btn-sm {
			font-size: 14px;
			color: white;
			padding: 5px 10px;
			border: none;
			margin-left: 5px;
			margin-right: 5px;
			margin-top: 16px;
		}

		.cancel {
			background: #EB7C63;
		}

		.confirm {
			background: #63E1C3;
		}

	}

	.show_box {
		display: block;
	}
}


.apply_card {
    background: white;
    padding: 40px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	text-align: center;
	@media (max-width:667px) {
		border-radius: 4px;
	}
}

.spec_tip {
	position: absolute;
    bottom: 10%;
}
