// .swiper-container {
	// 	width: 90%;
	// 	overflow: hidden;
	// 	height: 100%;
	// 	margin: auto;
	// }
	
	.swiper-clients {
		
		width: 90%;
		overflow: hidden;
		height: 100%;
		margin: auto;
		
		.swiper-slide {
			
			.img_wrap {
				height: 70px;
				vertical-align: middle;
				display: flex;
				align-items: center;
				margin-bottom: 30px;
			}
			
			img {
				display: block;
				max-width: 100%;
				margin: auto;
			}
			
			/* Center slide text vertically */
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
		}
		
		
		.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
			margin-top: 40px;
			padding-top: 40px;
			top: 100px;
		}
	}
	
	
	span.swiper-pagination-bullet.swiper-pagination-bullet-active {
		background: #004C7F;
	}
	
	.swiper-industry {
		
		text-align: center;
		padding-bottom: 80px;
		.item {
			width: 100%;
			margin-left: 0!important;
			margin-right: 0!important;
			margin-bottom: 20px!important;
			padding: 20px 17px!important;
		}
		
		.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
			margin-top: 120px;
			bottom: 40px;
		}
	}
	
	.swiper-planet {
		
		padding-bottom: 40px;
		
		.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
			bottom: 10px;
		}
		span.swiper-pagination-bullet.swiper-pagination-bullet-active {
			background: #16E7CF;
			opacity: 1;
		}
		
		.swiper-pagination-bullet {
			width: 8px;
			height: 8px;
			display: inline-block;
			border-radius: 100%;
			background: #fff;
			opacity: .5;
			margin-left: 5px;
			margin-right: 5px;
		}
	}
	
	@media (max-width: 667px) {
		.hide_mobile {
			display: none!important;
		}
		
		.border-bottom.padding_resize {
			border-bottom: none!important;
		}
		
		.form-row.mt-lg {
			margin-top: 24px;
		}
		
		.footer_mt_offset {
			margin-top: 0px;
			border-top: none!important;
			
			.btn_submit,
			.btn_submit_stroke {
				width: 100%;
				text-align: center;
				margin-bottom: 15px;
			}
		}
	}
	
	
	@media (max-width: 1200px) {
		header .container {
			max-width: 1130px;
		}
	}
	
	
	.padding_resize {
		@media (max-width: 1200px) {
			padding-top: 32px;
			padding-bottom: 32px;
			margin-bottom: 0;
		}
		
	}
	
	
	.progress_header {
		@media (max-width: 667px) {
			overflow-x: scroll;
			padding-top: 15px;
			padding-bottom: 15px;
			
			.row {
				width: max-content;
			}
		}
		
	}
	
	
	* {
		scrollbar-width: thin;
		scrollbar-color: #004C7F;
	}
	
	*::-webkit-scrollbar {
		width: 5px;
		height: 12px;
	}
	*::-webkit-scrollbar-track {
		background: #E8E9ED;
		height: 12px;
	}
	*::-webkit-scrollbar-thumb {
		background-color: #004C7F;
		border-radius: 20px;
		border: 3px solid #004C7F;
		height: 12px;
	}

	