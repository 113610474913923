.avatar_dropdown {
	a:hover {
		text-decoration: none;
	}
}

.action-offset {
	margin-top: 60px;
}

.chart_title,
.echart_legand {
	color: #797979;
	font-size: 12px;
}

.chart_title {
	margin-bottom: 0px;
	margin-left: 0px;
	margin-top: 0px;
}

.echart_legand {
	text-align: center;
	padding-right: 0px;
	margin-top: 0px;
}

.filter_dropdown {
	box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.07),
		0px 3px 14px rgba(0, 0, 0, 0.06), 0px 5px 5px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	border: none;
}

.item_dot_greyed {
	background: #d6d5d5;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	display: block;
	margin-right: 10px;
}

.engadgement_bar {
	display: block;
	background: #dedede;
	border-radius: 100px;
	position: relative;
	width: 100%;
	height: 32px;
	margin-bottom: 20px;
	overflow: hidden;

	.bar_inner {
		background: #3279c6;
		position: absolute;
		display: block;
		width: 0px;
		height: 32px;
	}

	.bordered_bar {
		border-right: 0px solid white;
	}
}

.overrun_bar {
	display: block;
	background: #eb7c63;
	border-radius: 100px;
	position: relative;
	width: 100%;
	height: 32px;
	margin-bottom: 20px;
	overflow: hidden;

	.bar_inner {
		background: #3279c6;
		position: absolute;
		display: block;
		width: 0px;
		height: 32px;
	}

	.bordered_bar {
		// border-right: 4px solid white;
	}
}

.working_hours {
	margin: auto;
}

.working_hours .legend_item {
	width: 100%;
	font-size: 14px;
	margin-bottom: 6px;

	.index-1 {
		background: #d6d5d5;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}

	.index-2 {
		background: #3279c6;
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 6px;
	}
}

.engaded_arrow {
	position: absolute;
	right: 20px;
	top: 10px;
	transform: rotate(180deg);
	transition: 0.3s;
}

.arrow_rotate {
	transform: rotate(0deg);
	transition: 0.3s;
}

.swiper-console {
	width: 100%;
	height: 100%;
	overflow: hidden;

	.swiper-slide {
		border: 1px solid #dedede;
		border-radius: 4px;
		padding: 20px;
	}

	.status_tag_primary {
		padding: 4px 8px;
		font-size: 12px;
		font-weight: normal;
		color: white;
		background: #131936;
		border: 1px solid #131936;
		border-radius: 4px;
		display: block;
		width: fit-content;
		margin-left: 0 !important;
	}

	.status_tag_primary_outline {
		padding: 4px 8px;
		font-size: 12px;
		font-weight: normal;
		color: #131936;
		border: 1px solid #131936;
		background: transparent;
		border-radius: 4px;
		display: block;
		width: fit-content;
		margin-left: 0 !important;
	}

	.bblue {
		border: 2px solid #3279c6;
	}

	.bmuted {
		background: #f2f2f2;
	}

	.bred {
		border: 2px solid #ffe2d6;
	}
}

.console-next,
.console-prev {
	position: absolute;
	top: 50%;
	width: calc(var(--swiper-navigation-size) / 44 * 27);
	height: var(--swiper-navigation-size);
	margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.console-prev,
.swiper-container-rtl .console-next {
	left: -20px;
	right: auto;
}
.console-next,
.swiper-container-rtl .console-prev {
	right: -20px;
	left: auto;
}

.console_header {
	background: #f8f8f9;
	border-radius: 4px;
	padding: 16px;

	.status_tag_grey {
		padding: 4px 8px;
		font-size: 14px;
		font-weight: normal;
		color: #333333;
		background: #e1e4ec;
		border-radius: 4px;
		display: block;
		width: fit-content;
		margin-left: 0 !important;
	}
}

.final_feedback_height {
	height: 85px;
}

.feedbackForm span.sr-only {
	display: none;
}

.flatTable {
	.table {
		thead th {
			font-family: Helvetica;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			color: black;
			padding-left: 20px;
			padding-right: 20px;
			vertical-align: middle;
		}

		tbody td {
			font-family: Helvetica;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: black;
			// border: none !important;
			border: 1px solid #dedede !important;
			padding-left: 20px;
			padding-right: 20px;
			vertical-align: middle;
		}
	}
}