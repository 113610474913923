.examTable {
	.table {
		thead th {
			font-family: Helvetica;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			color: black;
			padding-left: 0px;
			vertical-align: middle;
			border: none !important;
		}

		tbody td {
			font-family: Helvetica;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: black;
			border: none !important;
			padding-left: 0px;
			vertical-align: middle;
		}
	}

	.years .selectize-input {
		width: 140px !important;
	}

	.passed .selectize-input {
		width: 140px !important;
	}
}

.adminTableHolder {
	.table {
		thead th {
			font-family: Helvetica;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			color: black;
			padding-left: 20px;
			vertical-align: middle;
		}

		tbody td {
			font-family: Helvetica;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: black;
			border: none !important;
			padding-left: 20px;
			vertical-align: middle;
		}
	}
}

.flatTable {
	.table {
		thead th {
			font-family: Helvetica;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			color: black;
			padding-left: 20px;
			padding-right: 20px;
			vertical-align: middle;
		}

		tbody td {
			font-family: Helvetica;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: black;
			// border: none !important;
			border: 1px solid #dedede !important;
			padding-left: 20px;
			padding-right: 20px;
			vertical-align: middle;
		}
	}
}

.leftTable {
	.table {
		thead th {
			font-family: Helvetica;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			color: black;
			padding-left: 20px;
			vertical-align: middle;
		}

		tbody th {
			border: 1px solid #dedede !important;
			padding-left: 20px;
		}

		tbody td {
			font-family: Helvetica;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: black;
			border: 1px solid #dedede !important;
			padding-left: 20px;
			vertical-align: middle;
		}
	}
}

.adminReports {

	.dataTables_filter {
		float: right;
		margin-top: 75px;
	}

	.dataTables_length {
		margin-top: 40px;
	}


}




img.blog-thumb {
	max-width: 100px;
}

span.hidden-table {
	display: none;
}

.heat-low {
	color: #666;
}

.heat-medium {
	color: rgb(35, 104, 196);
}

.heat-high {
	color: rgb(13, 60, 122);
}

.heat-hot {
	color: rgb(253, 0, 0);
}

.employeeHolder div.dataTables_wrapper div.dataTables_filter {
    float: right;
    margin-bottom: 15px;
}

.employeeHolder .dt-buttons {
    margin-top: 15px;
	margin-bottom: 20px;
}


// Rock colors
.rock-red {
    color: red !important;
}

.rock-amber {
    color: rgb(232, 147, 29) !important;
}

.rock-green {
    color: green !important;
}