.progress_header {

	.progress_steps {
		padding-top: 6px;
	}

	.arrow	{
		float: left;
		padding-right: 30px;
		padding-top: 8px;

	}

	.item	{
		float: left;
		padding-right: 30px;

		.step {
			span {
				background: white;
				border: 1px solid #D0D1D7;
				width: 40px;
				height: 40px;
				text-align: center;
				border-radius: 20px;
				font-size: 14px;
				padding-top: 8px;
				color: theme-color(primary);
			}
		}

		.step_play {
			span {
				// background: theme-color(primary);
				// border: 1px solid theme-color(primary);;
				width: 40px;
				height: 40px;
				text-align: center;
				border-radius: 20px;
				font-size: 14px;
				// padding-top: 7px;
			}
		}

		.detail {
			padding-left: 16px;
			p {
				padding-bottom: 6px;
				color: theme-color(primary);
				line-height: 14px;
			}
			p.sm {
				font-size: 12px;
			}
			.text-muted {
				padding-bottom: 0;
			}
		}
	}

	.item.active {
		.step {
			span {
				background: #2B6AAD;
				border: 1px solid #2B6AAD;
				color: white;
			}
		}
		.detail {
			p {
				color: #2B6AAD;
			}
		}
	}

	.item.completed {
		.step {
			span {
				background: #05A4CA;
				border: 1px solid #05A4CA;
				color: white;
			}
		}
		.detail {
			p {
				color: theme-color(primary);
			}
		}
	}

	.bar {
		margin-top: 10px;
		display: block;
		width: 100%;
		background: #F0F1F3;
		border-radius: 19px;
		height: 8px;

		span {
			background: theme-color(secondary);
			border-radius: 19px;
			height: 8px;
			display: block;
			width: 0;
		}
	}

}


.bar-wrapper_thanks {
	.bar {
		margin-top: 10px;
		display: block;
		width: 200px;
		background: #F0F1F3;
		border-radius: 19px;
		height: 8px;
		margin: auto;
		span {
			background: theme-color(secondary);
			border-radius: 19px;
			height: 8px;
			display: block;
			width: 100%;
		}
	}
}

.pt-progress {
    padding-top: 30px;
    padding-bottom: 30px!important;
}
