.sidebar {
	padding: 36px 32px;
}

.search_results {
	padding: 48px 45px;
}

.form-control_sm {
	background: #ffffff;
	border: 1px solid #d6d5d5;
	box-sizing: border-box;
	border-radius: 4px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #797979;
	width: 100%;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-12 {
	margin-bottom: 12px;
}

.chip_grey {
	padding: 8px 12px;
	background: #e1e4ec;
	border-radius: 24px;
	font-size: 14px;
	display: inline-block;
	margin-right: 8px;
	color: #131936;
	margin-bottom: 8px;
	transition: 0.3s;

	&:hover {
		cursor: pointer;
		background: #3279c6;
		color: white;
	}
}

.chip_grey.selected {
	background: #3279c6;
	color: white;

	&:hover {
		cursor: pointer;
		background: #e1e4ec;
		color: #131936;
	}
}

.cutsomcheckbox {
	.checkbox {
		display: grid;
		grid-template-columns: min-content auto;
		grid-gap: 0.5em;

		&--disabled {
			color: var(--disabled);
		}
	}

	.checkbox__control {
		display: inline-grid;
		width: 20px;
		height: 20px;
		border-radius: 2px;
		border: 1px solid #d6d5d5;

		svg {
			transition: transform 0.1s ease-in 25ms;
			transform: scale(0);
			transform-origin: center center;
		}
	}

	.checkbox__input {
		display: grid;
		grid-template-areas: "checkbox";
		padding-top: 5px;

		> * {
			grid-area: checkbox;
		}

		input {
			opacity: 0;
			width: 20px;
			height: 20px;

			&:checked + .checkbox__control {
				background: #3279c6;
				border: 1px solid #3279c6;
			}

			&:checked + .checkbox__control svg {
				transform: scale(0.8);
			}

			&:disabled + .checkbox__control {
				color: var(--disabled);
			}
		}
	}

	.radio__label {
		padding-top: 5px;
		padding-left: 10px;
	}
}

.result_card {
	border-bottom: 1px solid #d6d5d5;
	padding-bottom: 42px;
	margin-bottom: 42px;

	// &:last-of-type {
	// 	border-bottom: none;
	// }

	.star_wrap span {
		padding-top: 5px;
	}

	.flag {
		border-radius: 4px;
	}

	.btn_stroke_results {
		display: inline-block;
		background: transparent;
		border: 2px solid #004c7f;
		border-radius: 4px;
		padding: 10px 24px;
		font-family: Helvetica;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		transition: 0.3s;
		color: #004c7f;
	}

	.btn_solid_results {
		display: inline-block;
		background: transparent;
		border: 2px solid #004c7f;
		border-radius: 4px;
		padding: 10px 24px;
		font-family: Helvetica;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		transition: 0.3s;
		background: #004c7f;
		color: white;
	}

	.advocate_card {
		background: #f8f8f9;
		border-radius: 8px;
		padding: 16px;
	}
}

.tag_wrapper {
	.btn-primary {
		color: #fff;
		background-color: transparent;
		border-color: transparent;
	}
}

// .toggle.btn {
//     min-width: 180px;
//     min-height: 47px;
// }

.toggle-off.btn {
	padding-left: 0px;
}

.toggle-on.btn {
	padding-right: 0px;
}

.toggle-off {
	background: #e1e4ec;
	border-radius: 24px;
	font-size: 14px;
	// margin-right: 8px;
	color: #131936;
	margin-bottom: 8px;
	transition: 0.3s;

	&:hover {
		cursor: pointer;
		background: #3279c6;
		color: white;
	}
}

.toggle-on {
	background: #3279c6 !important;
	border-radius: 24px;
	font-size: 14px;
	// margin-right: 8px;
	color: #ffffff;
	margin-bottom: 8px;
	transition: 0.3s;

	&:hover {
		cursor: pointer;
		background: #e1e4ec;
		color: #131936;
	}
}

.sidebar_grey_card {
	background: #f8f8f9;
	border-radius: 8px;
	padding: 16px;
}