.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
}

.upload-btn-wrapper input[type=file] {
	//ont-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
	position: absolute;
	height: 2px!important;
	width: 30px!important;
	background-color: #EB7C63;
	display: block;
	top: 19px!important;
	border-radius: 2px!important;
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
	left: 5px!important;
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
	right: 5px!important;
}

.swal2-icon.swal2-error {
	border-color: #f27474 !important;
}

// Custom upload buttons

#fileUpload {

	[type="file"] {
		border: 0;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		overflow: hidden;
		padding: 0;
		position: absolute !important;
		white-space: nowrap;
		width: 1px;
	}

	[type="file"] + label {
		display: inline-block;
		background: #004C7F;
		border: 2px solid #004C7F;
		border-radius: 4px;
		width: 196px;
		height: 48px;
		padding: 0px 0px 0px 24px;
		font-family: Helvetica;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		transition: 0.3s;
		color: white;
		cursor: pointer;
		line-height: 48px;

		@media (max-width:667px) {
			width: 100%;
			text-align: center;
		}
	}

	[type="file"]:focus + label,
	[type="file"] + label:hover {
		background: #2B6AAD;
		border: 2px solid #2B6AAD;
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}

	[type="file"]:focus + label {
		/* File upload focus state button styles */
	}

}

#fileUploadReplace {

	[type="file"] {
		border: 0;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		overflow: hidden;
		padding: 0;
		position: absolute !important;
		white-space: nowrap;
		width: 1px;
	}

	[type="file"] + label {
		display: inline-block;
		background: transparent;
		border: 2px solid #004C7F;
		border-radius: 4px;
		height: 48px;
		min-width: 135px;
		text-align: center;
		padding: 9px 24px 0px 24px;
		font-family: Helvetica;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		transition: 0.3s;
		color: #004C7F;
		cursor: pointer;
		line-height: 30px;
		margin-right: 10px;

		@media (max-width:667px) {
			width: 100%;
			text-align: center;
		}
	}

	[type="file"]:focus + label,
	[type="file"] + label:hover {
		background: #2B6AAD;
		border: 2px solid #2B6AAD;
		text-decoration: none;
		color: white;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
		cursor: pointer;
	}

	[type="file"]:focus + label {
		/* File upload focus state button styles */
	}

}

.resume-item {
	background: #F8F8F9;
	border-radius: 8px;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-left: -15px;
	padding-left: 15px;
	padding-right: 15px;
	margin-right: -15px;
	height: 89px;
	@media (max-width:667px) {
		border-radius: 4px;
	}
}

.file-valid {
	color: green !important;
}

.file-invalid {
	color: red !important;
}


.form-grey_box {
	background: #F8F8F9;
	border-radius: 4px;
	padding: 20px;

	.targetlist {
		padding-left: 38px;
	}

	.numbered {
		background: theme-color(primary);
		font-size: 16px;
		text-align: center;
		width: 24px;
		height: 24px;
		border-radius: 13px;
		color: white;
		display: inline-block;
		margin-right: 10px;
		padding-top: 1px;
		position: absolute;
		left: 34px;
	}
}

.modal_bullets {

	.numbered {
		background: theme-color(primary);
		font-size: 16px;
		text-align: center;
		width: 24px;
		height: 24px;
		border-radius: 13px;
		color: white;
		display: inline-block;
		margin-right: 10px;
		padding-top: 2px;
	}
}

input:focus::-webkit-input-placeholder
{
	color: transparent;
}


input[type="range"]{
	-webkit-appearance: none;
	width: 100%;
	height: 8px;
	outline: none !important;
	appearance:none;
	border:none;
	border-radius:30px;
	background: #D6D5D5;
}
input[type="range"]::-moz-focus-outer {
	border: 0;
}
input[type="range"]:hover {
	outline:none;
}

/* Chrome */

input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 16px;
	height: 16px;
	background: #131936;
	cursor: pointer;
	border-radius:30px;
	outline:none;
}

/* Moz */

input[type="range"]::-moz-range-thumb {
	width: 18px;
	height: 18px;
	background: #131936;
	cursor: pointer;
	border-radius:50%;
	border:none;
	outline:none;
}
input[type="range"]::-moz-range-progress {
	background: #131936;
	height: 100%;
	border-radius:30px;
	border:none;
}
input[type="range"]::-moz-range-track {
	background: #131936;
	border-radius:30px;
	border:none;
	height: 100%;
}

/* IE*/

input[type="range"]::-ms-fill-lower {
	background: #131936;
	height: 100%;
	border-radius:30px;
	border:none;
}
input[type="range"]::-ms-fill-upper {
	background: #131936;
	border-radius:30px;
	border:none;
	height: 100%;
}

.radio__label {
	font-family: Helvetica;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	.attendee-avatar {
		margin-right: 8px!important;
	}
}

.kbw-signature {
	display: inline-block;
	border: 1px solid #a0a0a0;
	-ms-touch-action: none;
}

.kbw-signature-disabled {
	opacity: 0.35;
}

.kbw-signature {
	width: 100%;
	height: 200px;
}

#sig canvas {
	width: 100% !important;
	height: auto;
}

.form-required .control-label:after {
    content: "*";
    color: #EB7C63;
    font-size: 20px;
    font-weight: bolder;
    top: 7px;
    left: 3px;
    position: relative;
}