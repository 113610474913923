// .js-cookie-consent.cookie-consent {
// 	@media (max-width: 667px) {
// 	 width: auto;
// 	 left: 0;
// 	 margin: 20px;
// 	 text-align: center;
// 	}
//  }

 .js-cookie-consent-agree.cookie-consent__agree:hover {
	 background: theme-color(accent);
	 border: 2px solid theme-color(accent);
	 text-decoration: none;
	 color: theme-color(primary);
	 box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
 }

//  .js-cookie-consent-agree.cookie-consent__agree {
// 	 @media (max-width: 667px) {
// 		 display: block;
// 		 width: 100%;
// 		 padding: 10px;
// 		 margin-bottom: 10px;
// 		}
//  }

 .js-cookie-consent-policy {
	 opacity: 0.7;
	 transition: 0.3s;

	&:hover {
		text-decoration: none;
		opacity: 1;
		color: white!important;
	}
 }
