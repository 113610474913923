.clients {

	@media (max-width: 667px) {
		padding-bottom: 40px;
	}

	.tagline  {
		font-size: 14px;
		line-height: 20px;
		font-style: italic;
		color: theme-color(secondary);
		font-weight: bold;
	}

}

// Common properties
$flag-width: 34px;
$flag-height: 22px;

// Two halfs flag
@function halfs($first, $second, $or: -180deg){
	@return linear-gradient($or, $first 50%, $second 50%);
}


%flag{
	width: $flag-width;
	height: $flag-height;
	border: 1px solid #999;
	display: inline-block;
	margin: 8px;
	box-shadow: 1px 1px 1px #CCC;
	transition: all .5s;
	margin-bottom: 1px;
	margin-top: -5px;
	position: relative;
	top: 6px;
	margin-left: 1px;

}
//Exceptions (not functions)
.flag--ukraine{
	@extend %flag;
	background: halfs(royalblue, yellow);
}


.partner {

	.card {

		img {
			margin-bottom: 24px;
		}

		h6 {
			margin-bottom: 16px;
		}

		p {
			min-height: 120px;
		}

		padding: 32px;
		padding-top: 32px;

		@media (max-width: 667px) {
			padding: 10px!important;
			margin-bottom: 20px;

			p {
				min-height: auto;
			}
		}
	}
}

.applicantsHIW {
	@media (max-width:667px) {
		padding-top: 48px!important;
		.mt-xl {
			margin-top: 0;
		}
	}
}

.how_it_works {

	padding-top: 176px;

	@media (max-width:667px) {
		.mt-xl {
			margin-top: 0;
		}
	}

	.background_shape {
		display: block;
		background: #F2F7FC;
		border-radius: 80px;
		max-width: 960px;
		max-height: 830px;
		width: 100%;
		height: 100%;
		top: -56px;
		position: absolute;
		z-index: -1;
		left: 0;
		right: 0;
		margin: auto;

		@media (max-width:667px) {
			width: 96%;
			max-height: 106%;
			border-radius: 30px;
			height: 140%;
		}
	}

	.arrow_wrapper {
		width: 10%;
		padding-left: 10px;
		padding-right: 10px;

		@media (max-width:667px) {
			width: 100%;
			margin-top: 18px;
			margin-bottom: 18px;
		}

		img {
			max-width: 40px;
			margin: auto;
		}

		.trailing_arrows {
			@media (max-width:667px) {
				display: none;
			}
		}

		.arrow_down {
			@media (min-width:667px) {
				display: none;
			}
		}
	}

	.card {

		width: 25%;
		box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
		border-radius: 8px;

		@media (max-width:667px) {
			width: 80%;
		}
		@media (max-width:667px) {
			border-radius: 4px;
		}

		p {
			margin-bottom: 0;
			padding-bottom: 15px;
			color: #797979;
		}

		.number {
			width: 48px;
			height: 48px;
			border-radius: 26px;
			font-size: 20px;
			font-weight: 600;
			color: white;
			text-align: center;
			display: inline-block;
			padding-top: 10px;
			background: theme-color(primary);
			float: left;
			opacity: 0.9;
		}

		h6 {
			color: theme-color(primary);
			display: inline-block;
			padding-left: 16px;
			float: left;
			width: 80%;
			margin-bottom: 0;
		}
	}
}

.stats {

	.card {
		background: white;

		@media (max-width:667px) {
			margin-bottom: 20px;
		}

		&:hover {
			background: $blue-100;

			h6, h3 {
				color: white!important;
			}
		}
	}
	.card-body {
		padding-left: 10px;
		padding-right: 10px;
		transition: 0.3;
		min-height: 155px;
	}

	h3 {
		font-weight: 900!important;
	}
}

.industry {

	.item {
		box-shadow: 0px 0px 16px rgba(10, 10, 10, 0.08);
		border-radius: 103px;
		padding: 20px 48px;
		width: fit-content;
		margin: auto;
		margin-bottom: 49px;
	}
}

.sollutions {
	.card {
		border-radius: 16px;

		.card-body {
			padding-left: 10px;
			padding-right: 10px;
		}

		&:last-of-type {
			@media (max-width:667px) {
				margin-bottom: 0;
			}
		}

	}
}

.mobile_solutions_item {


	.card {
		margin-bottom: 24px;
		border-radius: 32px!important;
		text-align: center;
		background: transparent;
		border: 1px solid rgba(255, 255, 255, 0.3)!important;

		.active {
			background: $blue-100!important;

			.btn {
				color: white;
			}
		}

		.card-header {
			padding: 6px;
			background: white;
			border-radius: 32px;

			img {
				margin-right: 10px;
			}
		}


		.btn {
			&:focus, &:visited {
				outline: none;
				box-shadow: none;
				text-decoration: none;
			}
			font-family: Helvetica;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			color: #333333;
		}
		.card-body {
			padding-left: 10px;
			padding-right: 10px;
			font-family: Helvetica;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			color: #FFFFFF;
			background: transparent;
			padding: 40px 20px;
		}
	}
}


.planet {
	min-height: auto;
	height: 100%;
	padding-bottom: 86px;

	img {
		@media (max-width:667px) {
			width: 65%;
		}
	}

	@media (max-width:667px) {
		padding-bottom: 30px;
	}


	.card {
		border-radius: 8px;
		@media (max-width:667px) {
			border-radius: 4px;
		}

		.card-body {
			padding: 30px;
		}
		.card-text {
			min-height: 110px;
		}
	}
}

.testimonials {

	h4 {
		color: $gray-700;
	}
	.quote {
		position: absolute;
		left: 0;
		top: -40px;

		@media (max-width: 667px) {
			left: 20px;
			top: -20px;
			width: 45px;
		}
	}
}

.audit {

	@media (max-width: 667px) {
		.fw-mobile {
			padding: 0;
		}
	}

	.hover-card {
		padding: 26px;
		margin-bottom: 16px;
		border-radius: 4px;
		transition: 0.3s;

		&:first-of-type {
			@media (max-width: 667px) {
				margin-top: 16px;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&:hover {
			background: #3279C6;
			h6, p {
				color: white!important;
			}
		}
	}

	.active {
		background: $blue-100;
	}

	.travel_image {
		min-height: 300px;
	}

}

.call {
	margin-top: 80px;
	padding-top: 80px;
	padding-bottom: 80px;

	@media (max-width: 667px) {
		margin-top: 48px;
		padding-bottom: 48px;
		padding-top: 48px;
	}
}

.solutions_item {

	position: relative;

	.circle {
		max-width: 350px;
		max-height: 350px;
		min-height: 350px;
		display: block;
		border: 2px solid rgba(255, 255, 255, 0.3);
		border-radius: 50%;
		background: transparent;
	}

	.btn_white {
		display: inline-block;
		background: white;
		border-radius: 32px;
		padding: 20px 48px;
		max-width: 330px;
		position: absolute;

		p {
			font-family: Helvetica;
			font-style: normal;
			font-weight: bold;
			display: inline-block;
			font-size: 24px;
			transition: 0.3s;
			color: theme-color(primary);
		}

		img {
			padding-right: 17px;
			display: inline-block;
		}

		&:hover {
			background: theme-color(secondary-hover);
			p {
				text-decoration: none;
				color: white;
			}
			box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);

		}
	}

	.active {
		background: theme-color(secondary-hover);
		p {
			text-decoration: none;
			color: white;
		}
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
	}

	.button_one.btn_white.d-flex.align-items-center {
		right: 50px;
		top: -40px;
	}

	.button_two.btn_white.d-flex.align-items-center {
		left: -150px;
		bottom: 70px;
	}

	.button_three.btn_white.d-flex.align-items-center {
		right: 0;
		bottom: 0;
	}

}

.hoverIconPeice {
	fill: #3279C6;
	transition: 0.3s;
}

.hover_card:hover .hoverIconPeice {
	fill: theme-color(accent);
}

.learning {
	min-height: auto;
	height: 572px;
	padding-bottom: 86px;

	img {
		@media (max-width:667px) {
			width: 65%;
		}
	}

	@media (max-width:667px) {
		height: 360px;
		padding-bottom: 30px;
	}

}


.learning2 {
	min-height: auto;
	height: 960px;
	padding-bottom: 86px;

	img {
		@media (max-width:667px) {
			width: 65%;
		}
	}

	@media (max-width:667px) {
		padding-bottom: 30px;
		height: 1360px;
	}

}

.learning-cards {

	.card {

		width: 100%;
		box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
		border-radius: 8px;
		min-height: 205px;
		margin-bottom: 30px;

		@media (max-width:667px) {
			width: 100%;
		}
		@media (max-width:667px) {
			border-radius: 4px;
		}

		p {
			margin-bottom: 0;
			padding-bottom: 15px;
			color: #797979;
		}

		.number {
			width: 48px;
			height: 48px;
			border-radius: 26px;
			font-size: 20px;
			font-weight: 600;
			color: white;
			text-align: center;
			display: inline-block;
			padding-top: 10px;
			background: theme-color(primary);
			float: left;
			opacity: 0.9;
		}

		h6 {
			color: theme-color(primary);
			display: inline-block;
			padding-left: 16px;
			float: left;
			width: 80%;
			margin-bottom: 0;
		}
	}

}

.targetlist {
    padding-left: 38px;
}

.targetpadding {
    position: absolute;
    left: 8px;
}

.tenthousand_kids {
	.row {
		height: 90vh;
	}

	.tag_box {
		background: white;
		padding: 20px;
		display: inline-block;
		margin-bottom: 40px;

		p {
			color: #131936;
			font-size: 20px;
			font-weight: bold;
		}

		.larger {
			font-size: 22px;
		}
	}

	.divider_line {
		background: #16E7CF;
		width: 100px;
		height: 3px;
		display: block;
	}

	.btn_accent_alt {
		font-size: 14px;
	}
}
