.profile_card {
	border: 1px solid #dedede;
	border-radius: 4px;
	padding: 24px;
	border-top: 4px solid theme-color(secondary);

	.profile_header {
		text-align: center;
		padding-top: 28px;
		padding-bottom: 20px;
		border-bottom: 1px solid #dbdde2;

		.profile_picture {
			display: block;
			border-radius: 50%;
			overflow: hidden;
			width: 120px;
			height: 120px;
			margin: auto;
			margin-bottom: 32px;
		}
	}

	.profile_body {
		.chart {
			margin: auto;
			height: 150px;
			margin-bottom: 20px;
		}

		.legend_item {
			width: 100%;
			font-size: 14px;
			margin-bottom: 6px;

			.index-1 {
				background: #3279c6;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin-right: 6px;
			}

			.index-2 {
				background: #3279c6;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin-right: 6px;
			}

			.index-3 {
				background: #3279c6;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin-right: 6px;
			}

			.index-4 {
				background: #3279c6;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin-right: 6px;
			}

			.index-5 {
				background: #3279c6;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin-right: 6px;
			}

			.index-6 {
				background: #3279c6;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin-right: 6px;
			}

			.index-7 {
				background: #3279c6;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin-right: 6px;
			}

			.index-8 {
				background: #3279c6;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin-right: 6px;
			}

			.index-9 {
				background: #3279c6;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin-right: 6px;
			}

			.index-10 {
				background: #3279c6;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin-right: 6px;
			}

			.index-11 {
				background: #3279c6;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin-right: 6px;
			}

			.index-12 {
				background: #3279c6;
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				margin-right: 6px;
			}
		}
	}

	.profile_footer {
		padding-top: 32px;

		.btn_default--stroke {
			font-size: 14px;
			padding: 14px;
			width: 100%;
			text-align: center;
		}
	}
}

.qualifications {
	display: block;
	width: 100%;

	.item {
		display: inline-block;
		background: #e1e4ec;
		border-radius: 4px;
		padding: 4px 8px;
		font-size: 14px;
		margin-right: 2px;
		margin-right: 2px;
		margin-bottom: 6px;
	}
}

.hint_card {
	background: #f2f2f2;
	border-radius: 4px;
	padding: 12px 16px;
}

.card_rates {
	padding: 24px 32px;
	.card-header {
		padding: 0;
		padding-bottom: 16px;
	}
}

.timesheet_item {
	background: #f8f8f9;
	border-radius: 4px;
	padding: 0px 0px;
	margin-left: 0;
	margin-right: 0;
}

.timesheet_card {
	display: block;
	width: 100%;
	padding: 16px 20px;

	&:hover {
		text-decoration: none;
	}
}

.timesheet_collapse {
	width: 100%;
}

.timesheet_collapse.show,
.timesheet_collapse.collapsing {
	background: transparent;

	.card-header,
	.card-body {
		background: transparent;
		padding-bottom: 0;
	}
}
