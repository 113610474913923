.intro {
	.email_form {
		height: 64px;
		border: 1px solid #d6d5d5;
		box-sizing: border-box;
		border-radius: 8px 0 0 8px;
		padding-left: 24px;
		border-right: none;
		width: 98%;
		margin-top: 1px;
		text-align: left;
		box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
		font-size: 20px;
		color: #333333;
		float: left;

		@media (max-width: 667px) {
			border-radius: 4px;
			width: 99%;
			border-right: 1px solid #d6d5d5;
		}

		&:focus,
		&:active {
			outline: none;
		}
	}

	.submit_btn {
		position: absolute;
		right: 15px;
		background: white;

		@media (max-width: 667px) {
			position: relative;
			margin: auto;
			text-align: center;
			right: 0;
			margin-top: 10px;
		}
	}
}

.applicants_hiw {
	.number {
		float: left;
	}

	h6 {
		width: 80%;
	}
}

.community {
	// background: #F6F6F6;
	padding-top: 80px;
	padding-bottom: 80px;

	.card {
		border-radius: 8px;
		background: white;
		opacity: 0.9;
		padding: 26px;
		box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
		border: none;
		@media (max-width: 667px) {
			border-radius: 4px;
		}
		p {
			font-style: italic;
		}
	}
}

.badge-makosi {
	background: transparent;
	color: #05a4ca;
	border: 1px solid #05a4ca;
	font-size: 16px;
	margin-left: 5px;
	padding: 8px;
	padding-bottom: 7px;
}

.referal {
	height: 100vh;
}

.rate_block span.gl-star-rating {
	display: inline-block;
}

.educational-error-message{
	margin-top: -1em;
	color: #FF0000;
	margin-left: 5em
}
.form-required .control-label:after {
	content:"*";
	color: red;
	font-size: 1.4em;
	font-weight: bolder;
	top: 7px;
	left: 1px;
	position: relative;
}

.form-required .control-tc:after {
	content: "*";
	color: red;
	font-size: 1.4em;
	font-weight: bolder;
	top: -14px;
	position: relative;
	left: 14.3em;
}
