.team_members {
	img {
		width: 160px;
	}

	@media (max-width: 667px) {
		h6 {
			min-height: 50px;
		}

		p {
			min-height: 50px;
		}
	}
}

.vacancy_link {
	display: block;
	text-decoration: none;
	position: relative;
	padding: 2rem;
	background-color: #16e7cf;
	cursor: pointer;
	color: #131936;
	height: 340px;

	p {
		text-decoration: none;
		position: relative;
		color: #131936;
		font-size: 14px;
		line-height: 20px;
		transition: 0.4s ease-out;
	}

}

.vacancy_link:before {
	position: absolute;
	left: 0;
	bottom: 0;
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	background-color: #131936;
	transform-origin: 0 bottom 0;
	transform: scaleY(0);
	transition: 0.4s ease-out;
}
.vacancy_link:hover .vacancy-title,
.vacancy_link:hover .vacancy_role,
.vacancy_link:hover .vacancy_date,
.vacancy_link:hover p {
	color: #ffffff !important;
	text-decoration: none;
}
.vacancy_link:hover:before {
	transform: scaleY(1);
}

.vacancy-title {
	text-decoration: none;
	position: relative;
	font-size: 2rem;
	font-weight: 700;
	line-height: 1.333;
	transition: 0.4s ease-out;
	margin-bottom: 20px;
	min-height: 90px;
	max-height: 90px;
	overflow: hidden;
}

.vacancy_role {
	text-decoration: none;
	position: relative;
	color: #131936;
	font-size: 14px;
	line-height: 20px;
	transition: 0.4s ease-out;
}

.vacancy_date {
	text-decoration: none;
	position: relative;
	color: #131936;
	font-size: 13px;
	margin-top: 20px;
	line-height: 20px;
	transition: 0.4s ease-out;
}


.join_header {
	background: #131936;
	min-height: 400px;
	height: 100%;

	.set_height {
		min-height: 400px;
		height: 100%;
	}

	// h1 {
		// 	padding-left: 32%;
		// }

		// h6 {
			// 	padding-right: 32%;
			// }
		}

		.join_link {
			display: block;
			text-decoration: none;
			position: relative;
			padding: 2rem;
			background-color: white;
			cursor: pointer;
			color: #131936;
			height: 360px;
			border-radius: 8px;
			overflow: hidden;
			&:hover {
				text-decoration: none!important;
			}

			p {
				text-decoration: none;
				position: relative;
				color: #131936;
				font-size: 14px;
				line-height: 20px;
				transition: 0.4s ease-out;
				&:hover {
					text-decoration: none!important;
				}
			}

		}

		.join_link:before {
			position: absolute;
			left: 0;
			bottom: 0;
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background-color: #131936;
			transform-origin: 0 bottom 0;
			transform: scaleY(0);
			transition: 0.4s ease-out;
			&:hover {
				text-decoration: none!important;
			}
		}

		.join_title {
			text-decoration: none;
			position: relative;
			font-size: 20px;
			font-weight: 700;
			transition: 0.4s ease-out;
			overflow: hidden;
			color: #131936;
			line-height: 29px;
			margin-bottom: -10px;
			height: 60px;
			&:hover {
				text-decoration: none!important;
			}
		}

		.join_role {
			text-decoration: none;
			position: relative;
			color: #131936;
			font-size: 14px;
			line-height: 20px;
			transition: 0.4s ease-out;
			&:hover {
				text-decoration: none!important;
			}
		}

		.join_date {
			text-decoration: none;
			position: relative;
			color: #05A4CA!important;
			font-size: 16px;
			margin-top: 10px;
			transition: 0.4s ease-out;
			font-weight: 700;
			&:hover {
				text-decoration: none!important;
			}
		}

		.join_link:hover .join_title,
		.join_link:hover .join_role,
		.join_link:hover p,
		.join_link:hover {
			color: #ffffff!important;
			text-decoration: none!important;
		}

		.join_link:hover .join_date {
			text-decoration: none!important;
			color: #05A4CA!important;
		}

		.join_link:hover .btn_primary {
			background: #009BC8;
		}
		.join_link:hover:before {
			transform: scaleY(1);
		}

		.join_link .btn_primary {
			display: inline-block;

			background: theme-color(primary);
			border-radius: 8px;
			padding: 14px 20px;

			font-family: Helvetica;
			font-style: normal;
			font-weight: bold;
			font-size: 15px;
			transition: 0.3s;
			color: white;

			position: absolute;
			bottom: 2rem;
			right: 40px;

			@media (max-width: 667px) {
				line-height: unset;
				font-size: 16px;
				// padding-top: 12px;
				// padding-bottom: 12px;
				width: 100%;
			}
			@media (max-width:667px) {
				border-radius: 4px;
			}

			.icon {
				padding-left: 17px;
				display: inline-block;
			}

			&:hover {
				background: theme-color(primary-hover);
				text-decoration: none;
				color: white;
				box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
				cursor: pointer;
			}
		}

		.text_row {
			position: absolute;
			max-width: 1280px;
			padding-right: 15px;
			padding-left: 0px;
			margin-right: auto;
			margin-left: auto;
			top: 15%;
			left: 0;
			right: 0;

			.text_box_left {
				padding-left: 80px;
			}

		}


		.vacancy_header {
			background: theme-color(primary);
			min-height: 370px;
			position: relative;
			overflow: hidden;

			img.wave {
				position: absolute;
				right: 0;
			}

			.vacancy_breadcrumb {
				background: transparent;
				position: absolute;
				top: 20px;
				left: 0;
				right: 0;

				img {
					padding-left: 10px;
					padding-right: 10px;
				}

				a {
					color: white!important;
					&:hover {
						color: #16E7CF;
						text-decoration: none;
					}
				}
				span {
					color: #16E7CF;
				}
			}

			.container_xl {
				width: 100%;
			}
		}


		.vacancy_details {
			padding: 50px;
			color: white;

			p, ul, li {
				color: white;
			}
		}

		.vacancy_description {
			padding: 50px;
			padding-left: 0px;
		}

		.vacancy_details:before {
			content: '';
			position: absolute;
			height: 100%;
			width: 100vw;
			top: 0;
			left: 0;
			background: #4A516B;
			z-index: -1;
		  }
