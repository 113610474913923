.subscribe_block {
	background: #F8F8F9;
	border-radius: 4px;
	padding: 48px;

	@media (max-width: 667px) {
		padding: 20px;
	}

	input, button {
		display: inline-block!important;
		height: 48px;
		min-height: 48px!important;
	}

	input {
		width: 70%;

		@media (max-width: 667px) {
			width: 100%;
			margin-bottom: 20px;
			margin-top: 20px;
		}
	}

	button {
		margin-left: 10px;
		border: none;
		margin-top: -3px;
		&:hover {
			border: none;
		}

		@media (max-width: 667px) {
			margin-left: 0;
			margin-top: 0;
		}
	}
}

#databaseSignupForm {
	::-webkit-input-placeholder { /* Edge */
		color: #999999;
	}

	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: #999999;
	}

	::placeholder {
		color: #999999;
	}
}


.article_back {
	position: absolute;
	top: 90px;
	font-size: 16px;
	color: #004C7F;
	font-weight: bold;
	transition: 0.3s;

	@media (max-width: 667px) {
		top: 40px;
	}

	&:hover {
		text-decoration: none;
		color: #131936;
	}
}

.search_back {
	font-size: 16px;
	color: #004C7F;
	font-weight: bold;
	transition: 0.3s;


	&:hover {
		text-decoration: none;
		color: #131936;
	}
}

.blog_link {

	&:hover {
		color: #004C7F;
		text-decoration: none;
	}

}

.gutenberg__content {

	p {
		margin-bottom: 24px;
	}

	ul, li {
		font-size: 16px;
		@media (max-width: 667px) {
			font-size: 14px;
		}

	}

	h2, h3 {
		font-size: 24px;
		color: #131936;
		line-height: 32px;
		font-weight: bold;
		margin-top: 40px;
	}

}

#social-links {
	float: left;
	ul {
		list-style: none;
		width: 100%;
		display: flex;
		padding-left: 30px;
	}

	li {
		display: inline-block;
		margin-right: 20px;
		font-size: 22px;
		margin-top: -5px;

		@media (max-width:667px) {
			margin-right: 10px;
			font-size: 20px;
			margin-top: -4px;
		}
	}

	.social-button {
		color: #3279C6;
		padding: 10px;

		&:hover {
			text-decoration: none;
			color: #004C7F;
		}

	}
}

.search_expand {
	float: left;
	height: 58px;
	line-height: 58px;

	@media (max-width: 667px) {
		display: none;
	}
}

.search-form {
	float: left;
	width: 59%;
	margin-left: 15px;
}

.search_wrapper .input-group-addon {
	position: absolute;
	top: 20px;
	right: 30px;
}

#closeSearch {
	margin-left: 10px;
}

.all_articles_link {
	margin-top: 10px;
	position: absolute;
	transition: 0.3s;

	&:hover {
		text-decoration: none;
		color: $primary;
	}
}


.blog_nav nav {
	display: inline-block;
}

.blog_nav {
	.page-item:first-child {
		font-size: 20px;
	}
}



.tag_cloud {
	padding: 10px 12px;
	background: #f8f8f8;
	border-radius: 20px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: #211600;
	display: inline-block;
}


.blog_image_full {
	width: 100%;
}


// highlight template

.article_header {
    background: #EAECF2;
    margin-bottom: 14rem;
}
.article_new_back {
    font-weight: bold;
    color: #104c7f;
}
.blog_image_header {
    width: 100%;
    border-radius: 20px;
    margin-top: 3rem;
    margin-bottom: -10rem;
}

.article_content {
    margin-top: 4rem;
}
.article_content p {
    color: #131936;
}

.article_content h5 {
    font-size: 20px;
    line-height: 30px;
}

.block_text {
    display: block;
    background: #131936;
    border-radius: 8px;
    padding: 30px 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.block_text p {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 0;
}