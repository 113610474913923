.profile_image_header {
    width: 100px;
    height: 100px;
}

.avatar-large-admin {
    font-size: 50px !important;
    line-height: 100px !important;
}

.badge-danger {
    color: #fff;
    background-color: #EB7C63;
}
html body .avatar-resizer{
	padding-left: 1em;
}
