footer {
	background: theme-color(primary);
	padding-top: 60px;
	padding-bottom: 40px;

	@media (max-width:667px) {
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 40px;
		padding-top: 40px;
	}

	.footer_col {
		width: 50%;
		margin-bottom: 40px;
		@media (min-width:667px) {
			width: 20%;
		}
	}

	.second_col {
		@media (max-width:667px) {
			padding-top: 5px;
		}
	}

	p {
		color: white;
		text-transform: uppercase;

		@media (max-width:667px) {
			font-size: 14px;
		}
	}

	.footer_link {
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #FFFFFF;
		opacity: 0.8;
		display: block;
		margin-bottom: 16px;
		transition:  0.3s;
		text-transform: none;
		&:hover {
			color: theme-color(accent);
		}

	}

	.btn_accent_alt {
		font-size: 14px;

		@media (max-width:667px) {
			margin-top: 0!important;
		}
	}
}

.footer_row {
	border-top: 1px solid rgba($color: #fff, $alpha: 0.1);

	p {
		text-transform: none;
		display: inline-block;
		font-size: 14px;
		margin-left: 40px;

		@media (max-width: 667px) {
			margin-left: 0;
		}
	}

	a {
		display: inline-block;
	}
}


.post_footer {
	background: theme-color(primary);;
	padding-bottom: 27px;

	.container {
		border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
		padding-top: 27px
	}

	p, span {
		color: #5A5E72;
		font-size: 14px;
		display: inline-block;
	}

	p {
		padding-right: 50px;

		@media (max-width: 667px) {
			padding-right: 0px;
			margin-bottom: 16px;
		}
	}

	span {
		padding-left: 20px;
		padding-right: 20px;
	}

	a {
		color: white;
		opacity: 1;
		transition: 0.3s;
		font-size: 14px;
		display: inline-block;

		&:hover {
			text-decoration: none;
			opacity: 0.6;
			cursor: pointer;
		}
	}

	.sm_links {

		.oneplanent {
			img {
				width: 100%;
				opacity: 1;
			}
		}

		a {
			transition: 0.3s;
			img {
				opacity: 0.4;
				transition: 0.3s;
			}
			@media (min-width: 667px) {
				padding-left: 25px;
			}

			&:hover {
				img {
					opacity: 1;
				}
			}
		}

		@media (max-width:667px) {
			img, a {
				width: 15%;
				text-align: center;

				img {
					width: auto;
				}
			}
		}
	}


}

.nav_btn--footer {
	display: inline-block;

	background: transparent;
	border: 2px solid theme-color(accent);
	border-radius: 4px;
	padding: 10px 24px!important;

	font-family: Helvetica;
	font-style: normal;
	font-weight: bold!important;
	font-size: 14px;
	transition: 0.3s;
	color: white;

	&:hover {
		background: theme-color(accent);
		border: 2px solid theme-color(accent);
		text-decoration: none;
		color: theme-color(primary);;
		box-shadow: 0px 0px 16px rgba(43, 106, 173, 0.24);
	}
}


footer.footer-dashboard {
	padding-top: 48px;
	padding-bottom: 20px;

	@media (max-width:667px) {
		.container {
			padding-left: 0;
			padding-right: 0;
		}
	}
}


// Cookie
.js-cookie-consent.cookie-consent {
	position: fixed;
	background: theme-color(primary);
	color: #fff;
	width: 100%;
	bottom: 0;
	left: 0;
	padding: 20px;
	border-radius: 0;
	z-index: 999999;
}

.js-cookie-consent h5 {
	font-size: 28px;
	margin-bottom: 20px;
	font-weight: bold;
	display: none;
}


span.cookie-consent__message {
	font-size: 14px;
	margin-bottom: 20px;
	display: inline-block;
	font-weight: 300;
}

.js-cookie-consent-agree.cookie-consent__agree {
	color: #fff;
	background-color: transparent;
	border: 2px solid #16E7CF;
	padding: 16px 48px;
	font-size: 16px;
	border-radius: 8px;
	display: inline-block;
	font-weight: bold;
	text-align: center;
	transition: 0.3s;
	@media (max-width:667px) {
		border-radius: 4px;
	}
}

.js-cookie-consent-policy {
	opacity: 1!important;
	color: theme-color(accent);

}

