// Moved this here as I dont know why by webpack is ignoring the pages/_team.scss include.
.team_members {
	@media(max-width:667px) {
		h6 {
			min-height: 50px;
		}

		p {
			min-height: 50px;
		}
	}
}
