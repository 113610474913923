@import url("https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap");

// Families

.font-inter {
	font-family: "Inter", sans-serif;
	font-weight: 900;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li,
hr,
label {
	font-family: Helvetica;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.text-disabled {
	color: #797979;
}

.text-99 {
	color: #999999;
}

.text-d5 {
	color: #d6d5d5;
}

h1 {
	font-size: 64px;
	line-height: 72px;
	font-weight: bold;

	@media (max-width: 667px) {
		font-size: 32px;
		line-height: 40px;
	}
}

h1.smaller {
	font-size: 56px;
	line-height: 64px;
	font-weight: bold;

	@media (max-width: 667px) {
		font-size: 32px;
		line-height: 40px;
	}
}

h2 {
	font-size: 48px;
	line-height: 64px;
	font-weight: bold;

	@media (max-width: 667px) {
		font-size: 24px;
		line-height: 32px;
	}
}

h2.xl {
	font-size: 72px;
	line-height: 84px;
	font-weight: bold;

	@media (max-width: 667px) {
		font-size: 34px;
		line-height: 32px;
	}
}

h2.sm {
	font-size: 40px;
	line-height: 64px;
	font-weight: bold;

	@media (max-width: 667px) {
		font-size: 24px;
		line-height: 32px;
	}
}

h3 {
	font-size: 40px;
	line-height: 48px;
	font-weight: bold;

	@media (max-width: 667px) {
		font-size: 32px;
		line-height: 48px;
	}
}

h4 {
	font-size: 32px;
	line-height: 48px;

	// @media (max-width: 667px) {
	// 	font-size: 16px;
	// 	line-height: 24px;
	// }
}

h4.featured_title {
	font-size: 32px;
	line-height: 48px;

	@media (max-width: 667px) {
		font-size: 24px;
		line-height: 32px;
	}
}

.testimonials h4 {
	@media (max-width: 667px) {
		font-size: 16px;
		line-height: 24px;
	}
}

h5 {
	font-size: 24px;
	line-height: 36px;
	@media (max-width: 667px) {
		font-size: 20px;
		line-height: 26px;
	}
}

h6 {
	font-size: 20px;
	line-height: 24px;
}

h6.lhsm {
	line-height: 28px;
	@media (max-width: 667px) {
		font-size: 20px;
		line-height: 24px;
	}
}

p {
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 0;

	@media (max-width: 667px) {
		font-size: 14px;
		line-height: 20px;
	}
}

a.sm,
p.sm {
	font-size: 14px;
	line-height: 20px;
}

p.xs,
a.xs,
.xs {
	font-size: 12px !important;
	line-height: 16px;
}

.tooltip-text-title {
	font-size: 14px;
}

.tooltip-text-sm {
	font-size: 11px;
	line-height: 16px;
}

.text-dark {
	color: #333 !important;
}

.text-79 {
	color: #797979;
}

.text-blue {
	color: #3279c6;
}

.text-red {
	color: red;
}

.text-red-always {
	color: red !important;
}

.text-green {
	color: green;
}

.text-orange {
	color: orange;
}

.font-size-12 {
	font-size: 12px !important;
	line-height: 18px !important;
}

.font-size-14 {
	font-size: 14px !important;
	line-height: 20px !important;
}

.font-size-16 {
	font-size: 16px !important;
	line-height: 24px !important;
}

.font-size-18 {
	font-size: 18px !important;
	line-height: 26px !important;
}

.font-size-60 {
	font-size: 60px !important;
	line-height: 60px !important;
}

.font-italic {
	font-style: italic !important;
}
