.rate_block {
	background: #F8F8F9;
	border-radius: 8px;
	padding: 20px;
	text-align: center;
	@media (max-width:667px) {
		border-radius: 4px;
	}
}


.referal_block {

	p {
		padding-top: 10px;
	}

	background: #F8F8F9;
	border-radius: 4px;
	padding: 20px 24px;
}


.referal_block_accent {

	p {
		padding-top: 10px;
	}

	background: #A8E6DF;
	border-radius: 4px;
	padding: 20px;
}
