.table-avatar {
	height: 48px;
	width: 48px;
	border-radius: 50%;
	background: #90b7e0;
	color: #fff;
	font-size: 25px;
	text-align: center;
	line-height: 50px;
}
.avatar-present{
	height: 100%;
	width: 100%;
	border-radius: 50%;
	background: #ffffff;
	color: #fff;
	font-size: 25px;
	text-align: center;
	line-height: unset;
}
.auth-avatar-present {
	width: 110%;
	border-radius: 50%;
	background: #ffffff;
	color: #fff;
	font-size: 60px;
	text-align: center;
}

.nav-avatar-present{
	height: 36px;
	width: 36px;
	border-radius: 50%;
	background: #ffffff;
	color: #fff;
	font-size: 18px;
	text-align: center;
	line-height: 36px;
}
.table-avatar-present {
	height: 48px;
	width: 48px;
	border-radius: 50%;
	background: #ffffff;
	color: #fff;
	font-size: 25px;
	text-align: center;
	line-height: 50px;
}
.table-avatar-sml {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border-radius: 50%;
	background: #90b7e0;
	color: #fff;
	font-size: 25px;
	text-align: center;
	line-height: 50px;
}

.dropdown-toggle {
	&:hover {
		text-decoration: none;
	}
}

.nav-avatar {
	height: 36px;
	width: 36px;
	border-radius: 50%;
	background: #90b7e0;
	color: #fff;
	font-size: 18px;
	text-align: center;
	line-height: 36px;
}

.avatar_dropdown .dropdown-toggle::after,
.web-avatar .dropdown-toggle::after {
	display: none;
}

// .profile-avatar {
//     height: 100%;
//     width: 100%;
//     border-radius: 50%;
//     background: #90B7E0;
//     color: #fff;
//     font-size: 50px;
//     text-align: center;
//     line-height: 115px;
// }

.profile_picture {
	height: 50px;
	width: 50px;
}

.profile-avatar {
	height: 100px;
	width: 100%;
	border-radius: 50%;
	background: #90b7e0;
	color: #fff;
	font-size: 25px;
	text-align: center;
	line-height: 50px;
}

.search-avatar {
	height: 110px;
	width: 110px;
	border-radius: 50%;
	background: #90b7e0;
	color: #fff;
	font-size: 25px;
	text-align: center;
	line-height: 110px;
}

.avatar-large {
	font-size: 50px !important;
	line-height: 115px !important;
}

.img-round {
	border-radius: 50%;
}

.profile-avatar {
	height: 100%;
	width: 100%;
	border-radius: 50%;
	background: #90b7e0;
	color: #fff;
	font-size: 25px;
	text-align: center;
	line-height: unset;
	// padding-top: 5px;
}

img.cropped-avatar {
	height: 200px;
}

img.cropped-avatar-member {
	height: 150px;
	border-radius: 100%;
}
span.dz-margin-resizer{
	margin-top: 20px;
}

.br-50 {
	border-radius: 50% !important;
}
