.portal_modal {

	.content_box {
		background: #F8F8F9;
		border-radius: 4px;
		padding: 24px 20px ;
	}

	.badge {
		background: #E1E4EC;
		border-radius: 4px;
		font-size: 14px;
		padding: 4px 8px;
		color: #333;
	}

}
