.opportunity_calander {

	.table thead th {
		font-family: 'Inter', sans-serif;
		font-weight: normal;
		font-size: 14px;
		color: #999999;
		padding-left: 0;
		text-align: center;
		border-bottom: none;
		padding: 0;
		padding-bottom: 10px;
	}

	tr, td {
		text-align: center;
		font-family: 'Inter', sans-serif;
		font-weight: normal;
		font-size: 14px;
		color: #999999;
	}

	.date {
		border: none;
		background: transparent;
		border: none;
		width: 40px;
		height: 40px;
		border-radius: 2px;
		color: #999999;
		transition: 0.3s;

		&:hover {
			background: #DEDEDE;
			color: #131936;
		}
	}

	.available {
		background: #F2F2F2;
	}

	.unavailable {
		border: 1px solid #DEDEDE;
		position: relative;
		overflow: hidden;
		color: #999999!important;
		transition: 0.3s;

		&:hover {
			background: #F2F2F2;
		}

		&:after {
			content: "";
			display: block;
			width: 1px;
			height: 70px;
			transform: rotate(45deg);
			background: #DEDEDE;
			position: absolute;
			top: -10px;
			left: 12px;
		}
	}

	.unavailable-selected {
		border: 1px solid #3279C6;
		position: relative;
		overflow: hidden;
		background: transparent!important;
		color: #999999!important;

		&:after {
			content: "";
			display: block;
			width: 1px;
			height: 70px;
			transform: rotate(45deg);
			background: #3279C6;
			position: absolute;
			top: -10px;
			left: 12px;
		}
	}

	.selected {
		background: #3279C6;
		color: white;
		&:hover {
			background: #3279C6;
			color: white;
		}
	}


}

.time_selector {
	background: #F8F8F9;
	border-radius: 4px;
	padding: 24px;

	.time_all {
		border-bottom: 1px solid #DEDEDE;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}

	.toggle_wrapper label {
		margin-top: 0;
	}
}

.time_selector.hidden {
	display: none;
}

.info_message {
	background: #F8F8F9;
	border-radius: 4px;
	padding: 12px 16px;
	margin-top: 40px;

	p {
		color: #555;
	}
}

.info_message.error {
	border-radius: 4px;
	padding: 12px 16px;
	margin-top: 40px;
	background: rgba(235, 124, 99, 0.1);
	border: 1px solid #EB7C63;

	p {
		color: #555;
	}
}

.schedule_calander {

	.table thead th {
		font-family: 'Inter', sans-serif;
		font-weight: normal;
		font-size: 14px;
		color: #999999;
		padding-left: 0;
		text-align: center;
		border-bottom: none;
		padding: 0;
		padding-bottom: 10px;
	}

	tr, td {
		text-align: center;
		font-family: 'Inter', sans-serif;
		font-weight: normal;
		font-size: 14px;
		color: #999999;
		padding: 5px;
	}

	.date {
		border: none;
		background: transparent;
		border: none;
		width: 70px;
		height: 40px;
		border-radius: 2px;
		color: #999999;
		transition: 0.3s;

		&:hover {
			background: #DEDEDE;
			color: #131936;
		}
	}

	.available {
		background: #F2F2F2;
	}

	.unavailable {
		border: 1px solid #DEDEDE;
		position: relative;
		overflow: hidden;
		color: #999999!important;
		transition: 0.3s;

		&:hover {
			background: #F2F2F2;
		}

		&:after {
			content: "";
			display: block;
			width: 1px;
			height: 170px;
			transform: rotate(60deg);
			background: #DEDEDE;
			position: absolute;
			top: -42px;
			left: 18px;;
		}
	}

	.unavailable-selected {
		border: 1px solid #3279C6;
		position: relative;
		overflow: hidden;
		background: transparent!important;
		color: #999999!important;

		&:after {
			content: "";
			display: block;
			width: 1px;
			height: 170px;
			transform: rotate(60deg);
			background: #3279C6;
			position: absolute;
			top: -42px;
			left: 18px;
		}
	}

	.selected {
		background: #3279C6;
		color: white;
		&:hover {
			background: #3279C6;
			color: white;
		}
	}

}

.hidden {
	display: none;
}

.date.disabled {
    cursor: not-allowed;
}


:root {
	--color: #3279C6;
	--disabled: #959495;
}

.attendees {

	.checkbox {
		display: grid;
		grid-template-columns: min-content auto;
		grid-gap: 0.5em;

		&--disabled {
			color: var(--disabled);
		}
	}

	.checkbox__control {
		display: inline-grid;
		width: 20px;
		height: 20px;
		border-radius: 2px;
		border: 1px solid #D6D5D5;

		svg {
			transition: transform 0.1s ease-in 25ms;
			transform: scale(0);
			transform-origin: center center;
		}
	}

	.checkbox__input {
		display: grid;
		grid-template-areas: "checkbox";
		padding-top: 5px;

		> * {
			grid-area: checkbox;
		}

		input {
			opacity: 0;
			width: 20px;
			height: 20px;

			&:checked + .checkbox__control {
				background: #3279C6;
				border: 1px solid #3279C6;
			}

			&:checked + .checkbox__control svg {
				transform: scale(0.8);
			}

			&:disabled + .checkbox__control {
				color: var(--disabled);
			}
		}
	}
}

.attendee-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #90B7E0;
    color: #fff;
	margin-left: 8px!important;
    font-size: 14px;
    text-align: center;
	// padding-top: 8px;
	line-height: 40px;
	display: inline-block;
	margin-right: 12px!important;
}


.interview_message {
	background: #CCEEFF;
	border-radius: 4px;
	padding: 12px 16px;

	p {
		text-align: center;
		color: #555;
	}
}

.display_timeslot label {
    font-size: 12px !important;
}
