.jump-wrapper {

	position: relative;

	.timeline {
		display: block;
		position: absolute;
		height: 100%;
		width: 1px;
		top: 10px;
		left: 6px;
		background: #DEDEDE;
		z-index: -1;
	}


	.indicator {
		width: 40px;
		display: inline-block;
		margin-top: 8px;
		transition: 0.3s;
		span {
			width: 8px;
			margin-left: 2px;
			height: 8px;
			display: block;
			background: #DEDEDE;
			border-radius: 4px;
			transition: 0.3s;

			img {
				display: none;
				transition: 0.3s;
			}
		}
	}

	.description {
		display: inline-block;
		padding-right: 10px;
		font-size: 14px;
		transition: 0.3s;

		.playin {
			display: none;
		}

		.timestamp {
			font-size: 12px;
			color: #999999;
		}
	}

	.passed .indicator {
		transition: 0.3s;
		span {
			width: 8px;
			height: 8px;
			display: block;
			background: #2CE9D8;
			border-radius: 4px;
			transition: 0.3s;
		}
	}

	.active .description {
		transition: 0.3s;

		.playin {
			display: inline-block;
		}
		.text-primary {
			font-weight: bold;
			transition: 0.3s;
		}
	}

	.active .indicator {
		transition: 0.3s;
		span {
			margin-left: -2px;
			width: 16px;
			height: 16px;
			display: block;
			background: #131936;
			transition: 0.3s;
			border-radius: 8px;
			@media (max-width:667px) {
				border-radius: 4px;
			}

			img {
				display: block;
				padding-left: 6px;
				padding-top: 5px;
				transition: 0.3s;
			}
		}
	}

	.item {
		transition: 0.3s;
		.text-primary {
			font-weight: normal;
			transition: 0.3s;
			transition: 0.3s;
		}

		&:hover {
			transition: 0.3s;
			.text-primary {
				font-weight: bold;
				cursor: pointer;
			}
		}
	}
}

.plyr__control--overlaid {
    background: #2CE9D8!important;
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
    background: #2CE9D8!important;
    background: var(--plyr-video-control-background-hover,var(--plyr-color-main,var(--plyr-color-main,#00b3ff)));
    color: #fff;
    color: var(--plyr-video-control-color-hover,#fff);
}

.plyr--full-ui input[type=range] {
    color: #2CE9D8!important;
}



.rating {
	background: #fff;
    width: 240px;
    margin-left: auto;
    margin-right: auto;

	input[type="radio"] {
		position: fixed;
		top: 0;
		right: 100%;
	}

	label {
		font-size: 1.5em;
		padding: 0.5em;
		margin: 0;
		float: left;
		cursor: pointer;
		@include transition(0.2s);
	}

	input[type="radio"]:checked ~ input + label {
		background: none;
		color: #aaa;
	}

	input + label {
		background: theme-color(primary);
		margin: 0 0 1em 0;
	}

	input + label:first-of-type {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}

	input:checked + label {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}

}
