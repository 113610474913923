.iti {
  display: block;
}

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
  border-right: 1px solid #D6D5D5;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: white;
}
// Generic Styles
.was-validated .date-of-birth:valid, .date-of-birth.is-valid {
	border-color: #28a745;
	padding-right: calc(1.5em + 0.75rem);
	background-repeat: no-repeat;
	background-position: right calc(2.475em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

label {
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #797979;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #D6D5D5;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-group, .form-row {
  margin-bottom: 17px;
}

.form-group {
    margin-bottom: 28px;
    margin-top: 0!important;
}


#contact_header {
	h6 {
		margin-top: 18px;
		margin-bottom: 32px;
	}

	h5 {
		margin-bottom: 10%;
	}
}
