.admin-tabs {
	.nav-link {
		color: #a2a2a2 !important;
		display: block;
		padding: 1rem 1.5rem;

		.active {
			color: #004c7f !important;
		}
	}
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #004c7f !important;
}

.admin-tab-content .tab-content {
	background: #fff;
	padding: 40px;
	border-left: 1px solid #dedede;
	border-right: 1px solid #dedede;
	border-bottom: 1px solid #dedede;
}

.vertical-tab-data {
	border-right: 0px !important;
	border-bottom: 0px !important;
	padding-top: 0px !important;
	padding-right: 0px !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #fff;
	background-color: #004c7f;
}

.tab-tag {
	background: #6c757d;
	color: #ffffff;
	font-size: 12px;
	padding: 3px 6px;
	font-weight: bold;
	border-radius: 20px;
}

.admin-min-tabs {
	.nav-link {
		color: #a2a2a2 !important;
		display: block;
		padding: 1rem 1rem;
		font-size: 13px;

		.active {
			color: #004c7f !important;
		}
	}
}
