.video-container {
	position: relative;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 80vh;
	overflow: hidden;
}

.video-container video {
	/* Make video to at least 100% wide and tall */
	min-width: 100%;
	min-height: 100%;

	/* Setting width & height to auto prevents the browser from stretching or squishing the video */
	width: auto;
	height: auto;

	/* Center the video */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

.overlay {
	background: rgba(0,0,0,0.5);
	position: relative;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	margin: auto auto 1em;
	box-sizing: border-box;
	padding: 2em;
	line-height: 1.5;
	text-align: center;
	height: 80vh;
	z-index: 99;

	:last-child { margin-bottom: 0; }
}

.impact_hover_blocks {

	.bg_overlay_hover {
		background: #131936;
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 10;
		opacity: 0;
		transition: 0.3s;
	}

	.content_block {
		z-index: 99;
	}

	.block_item {

		min-height: 475px;

		.content_block {
			text-align: center;
			max-width: 475px;
			margin: auto;
		}
	}

	.block_item:hover .bg_overlay_hover {
		opacity: 0.6;
	}

	img {
		width: 100%;
	}
}

.number_run_blocks {
	img {
		margin-bottom: 20px;
	}
}

.logo_group {
	border-radius: 4px;
	overflow: hidden;
}

@media (max-width:667px) {
	.swiper-container {
		margin-left: 10px;
		margin-right: 10px;
		border-radius: 8px;
		overflow: hidden;
	}
}

.swiper-impact {
	.swiper-slide {
		min-height: 780px;

		@media (max-width:667px) {
			min-height: 430px;
			text-align: center;
			padding-left: 10px;
			padding-right: 10px;

			h4 {
				font-size: 24px;
				line-height: 32px;
				text-align: center;
			}

			.logo_image {
				max-width: 140px;
			}
		}

		p {
			@media (max-width:667px) {
				display: none;
			}
		}
	}
}

.swiper-pagination-impact {
	bottom: 30px;


	span.swiper-pagination-bullet.swiper-pagination-bullet-active {
		background: #141936;
		opacity: 1;
	}
	.swiper-pagination-bullet {
		width: 12px;
		height: 12px;
		display: inline-block;
		border-radius: 100%;
		background: #000;
		opacity: .2;
		margin-right: 6px;
		margin-left: 6px;
	}
}

.swiper-button-prev-impact {
	width: 30px;
	position: absolute;
	left: 20px;
	top: 50%;
	z-index: 99;
	&:focus {
		border: none;
		outline: none;
	}
	@media (max-width:667px) {
		display: none;
	}
}

.swiper-button-next-impact {
	width: 30px;
	position: absolute;
	right: 30px;
	top: 50%;
	z-index: 99;
	&:focus {
		border: none;
		outline: none;
	}
	@media (max-width:667px) {
		display: none;
	}
}

.impact_block_blue {
	background: #F2F7FC;
	border-radius: 8px;
	padding: 40px 32px;

	h5 {
		min-height: 200px;
	}
}


// Mobile Changes

@media (max-width:667px) {
	.impact_h3 {
		font-size: 23px!important;
		line-height: 30px;
	}

	.block_item_hb {
		position: relative;
		text-align: center;
		margin: 20px;
		padding: 30px 15px;
		border-radius: 8px;
		overflow: hidden;

		.content_block h4 {
			font-size: 24px;
			line-height: 32px;
		}

		.bg_image_cover {
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			left: 0;
			z-index: -1;
			width: auto;
		}
	}

	#inViewport {
		background: #F2F7FC;
		padding-top: 48px;
		padding-bottom: 48px;

		h4 {
			font-size: 16px;
			line-height: 24px;

			span {
				font-size: 32px;
			}
		}

		.nr_block {
			border: 1px solid #E0E1E7;
			border-radius: 16px;
			padding: 24px;
			margin-bottom: 20px;
		}
	}

	.number_run_blocks img {
		display: none;
	}

	.impact_block_blue h5 {
		font-weight: normal!important;
		font-size: 16px;
		line-height: 24px;
		min-height: auto;
		margin-bottom: 24px;
	}

	.bg_grey.mobilechange {
		background: white;
	}

	.impactfooterblocks {

		background: #F8F8F9;

		.impact_block_blue {
			background: #FFFFFF;
		}

	}
}
@media (min-width:667px) {
	.hide_desktop {
		display: none;
	}
}

@media (max-width:667px) {
	.hide_mobile {
		display: none;
	}
}

.coming_soon {
	height: 60vh;
	text-align: center;

	@media (max-width:667px) {
		height: 100vh;
		background-position: center!important;
	}

	h2 {
		color: white;
		font-size: 64px;
		font-weight: bold;
		line-height: inherit;
		margin-bottom: 60px;

		@media (max-width:667px) {
			font-size: 34px;
			margin-bottom: 30px;
		}
	}

	p {
		color: white;
		display: block;
		margin: auto;
		margin-bottom: 60px;

		@media (max-width:667px) {
			margin-bottom: 30px;
		}
	}
	.gradient_button {
		background: linear-gradient(97.3deg, #16E7CF -52.55%, #009BC8 109.29%);
		border-radius: 8px;
		border-radius: 8px;
		padding: 20px 26px;

		font-family: Helvetica;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		transition: 0.3s;
		color: white;
		display: inline-block;
		transition: all 0.3s;

		img {
			margin-left: 10px;
		}

		&:hover {
			text-decoration: none;
			background: linear-gradient(97.3deg, #009BC8 -52.55%, #16E7CF 109.29%);
		}
	}
}

.impact {

	padding-top: 100px;
	padding-bottom: 100px;

	@media (max-width: 667px)  {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.divider {
		width: 94px;
		height: 3px;
		background: linear-gradient(97.3deg, #009BC8 -52.55%, #16E7CF 109.29%);
		display: inline-block;
		margin-bottom: 40px;
	}

	.text-alt {
		color: #4A516B;
		font-weight: bold;
		line-height: 28px;
	}
}

.why {
	background: #3279C6;
	padding-top: 100px;
	padding-bottom: 100px;
	position: relative;

	@media (max-width: 667px)  {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.container {
		position: relative;
		z-index: 99;
	}
}

.bean {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1;
}

.how {
	padding-top: 100px;
	padding-bottom: 100px;

	@media (max-width: 667px)  {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.divider {
		width: 94px;
		height: 3px;
		background: linear-gradient(97.3deg, #009BC8 -52.55%, #16E7CF 109.29%);
		display: inline-block;
		margin-bottom: 10px;
	}

	.how_image_wrapper {
		margin-top: 60px;
		padding-top: 94px;
		padding-bottom: 94px;
		text-align: center;
		border-radius: 8px;
		overflow: hidden;

		@media (max-width: 667px) {
			background-position: center!important;
		}

		h4 {
			width: 80%;
			font-weight: bold;
			margin: auto;

			@media (max-width: 667px) {
				font-size: 24px;
				line-height: 34px;
			}
		}
	}
}


.lets {
	background: #131936;
	padding-top: 100px;
	padding-bottom: 100px;

	.choice_box {
		background: #4A516B;
		border-radius: 8px;
		padding: 45px 50px;
		transition: 0.3s;
		min-height: 440px;

		h5, h6 {
			color: white;
			margin-bottom: 30px;
			transition: 0.3s;
		}

		h5 {
			min-height: 210px;
		}


		.btn_white {
			display: inline-block;

			background: white;
			border-radius: 8px;
			padding: 14px 48px;

			font-family: Helvetica;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			transition: 0.3s;
			color: theme-color(primary);

			@media (max-width: 667px) {
				font-size: 16px;
			}
		}

		&:hover{
			background: white;

			h6 {
				color:#4A516B;
			}

			h5 {
				color: theme-color(primary);
			}

			.btn_white {
				background: #3279C6;
				color: white;
				text-decoration: none;
			}
		}
	}
}
