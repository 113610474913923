// Spacings

.section_spacing {
	padding-top: 116px;

	@media (max-width: 667px) {
        padding-top: 48px;
    }
}

.pt-lg {
	padding-top: 80px;
}

.pb-lg {
	padding-bottom: 80px;
}

.pt-md {
	padding-top: 40px;
}

.pb-md {
	padding-bottom: 40px;
}

.mb-12px {
	margin-bottom: 12px;
}

.py-xl {
	padding-bottom: 110px;
	padding-top: 110px;

	@media (max-width: 667px) {
		padding-bottom: 48px;
		padding-top: 48px;
	}
}

.mt-xl {
	margin-top: 110px;

	@media (max-width: 667px) {
		margin-top: 48px;
	}
}

.mb-xl {
	margin-bottom: 110px;

	@media (max-width: 667px) {
		margin-bottom: 48px;
	}
}

.mt-lg {
	margin-top: 80px;

	@media (max-width: 667px) {
		margin-top: 48px;
	}
}

.mb-lg {
	margin-bottom: 80px;
}

.mt-md {
	margin-top: 40px;
}

.mb-md {
	margin-bottom: 40px;
}

.ml-7 {
    margin-left: 7em;
}

.mm-12 {
    margin-top: -6px;
}

.mt-100 {
    margin-top: 100px;
}

.pad-40 {
	padding: 40px;
}

.pt-progress {
	padding-top: 30px;
}

// Specific grid adjustments

.row.gutter_md > .col,
.row.gutter_md > [class*="col-"] {
	padding-right: 45.2px;
	padding-left: 45.2px;

	&:first-of-type {
		padding-left: 0;
	}

	&:last-of-type {
		padding-right: 0;
	}
}

.row-nogutter {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.row-nogutterpadding {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.row-30gutter {
	margin-left: -30px !important;
	margin-right: -30px !important;
}

@media (min-width: 1200px) {
	.container_xl {
		max-width: 1280px;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}
}

@media (max-width: 1200px) {
	.container_xl {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: 667px) {
	.container_xl {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}
}


// Custom Backgrounds

.bg_primary_fade {
	background: #F2F7FC;

}

.bg-gray {
	background: #f7f7f7;
}


.bg-light-gray {
	background: #F8F8F9;
}

.bg-withdrawn {
    background-color: #333333 !important;
}

.bg-status {
    background: rgba(204, 238, 255, 0.5) !important;
}

.bg-impersonating {
	background-color: red !important;
}

.clear {
	clear: both;
}

// Borders

.border-bottom {
    border-bottom: 1px solid #EDEDED !important;
}


.height_100 {
	min-height: 100vh;
	height: 100%;
}

.text-black {
	color: black!important;
}

.badge {
	font-weight: 400!important;
}

.badge-padding {
	padding: 8px !important;
}

.overflowFix {
	overflow: visible!important;
}

.br-4 {
	border-radius: 4px;
}

.br-8 {
	border-radius: 8px;
	@media (max-width:667px) {
		border-radius: 4px;
	}
}

.mb-32 {
	margin-bottom: 32px;
}

.timesheet_img {
    width: 100%;
}
